import { FormDropdown, Validator } from 'design-system';

const genderPassportOptions = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Unspecified/Other',
    value: 'O',
  },
];

export const GenderDropdown = ({
  validateFields,
  size,
  label,
}: {
  label?: string;
  validateFields?: string[];
  size?: 'small' | 'medium' | 'large' | 'fullWidth';
}) => {
  return (
    <FormDropdown
      name="gender"
      options={genderPassportOptions}
      validate={Validator.required}
      validateFields={validateFields}
      TextInputProps={{
        size: size ?? 'large',
        label: label ?? 'Gender (as on passport)',
        placeholder: 'Select...',
        fullWidth: true,
        testid: 'create-account-gender',
        inputProps: {
          autoComplete: 'sex',
        },
      }}
    />
  );
};
