import { Box } from '@mui/system';
import { FlashPackWordLogoIcon, Link, useTheme } from 'design-system';
import React from 'react';
import { useMediaQuery } from '@mui/material';

type HeaderProps = {
  displayPaymentMethods?: boolean;
};

const Header: React.FC<HeaderProps> = ({ displayPaymentMethods }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        ...(isMobile && {
          position: 'fixed',
          top: 0,
          zIndex: 10,
        }),
        backgroundColor: theme.palette.principal.white,
        height: 74,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: !displayPaymentMethods
          ? `1px solid ${theme.palette.principal.grey50}`
          : 'none',
      }}
    >
      <Link href={'https://www.flashpack.com/'} sx={{ display: 'inline-flex' }}>
        <FlashPackWordLogoIcon sx={{ height: 50, width: 72 }} />
      </Link>
    </Box>
  );
};

export default Header;
