import {
  useBasket,
  useExpiredReservationRedirect,
  useOrderDetails,
  useOutOfAgeRangeRedirect,
  useRouting,
} from '@src/shared/hooks';
import Layout from '@src/shared/Layout/Layout';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import {
  CheckoutSectionName,
  CheckoutSections,
} from '@src/v3/checkout-sections/CheckoutSections';
import { Stack, useMediaQuery, useTheme } from 'design-system';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { useEffect, useState } from 'react';
import { OrderDetailsV3 } from './order-details-v3/OrderDetailsV3';
import {
  CurrentMinimalCustomerDetailsDocument,
  CustomerCurrency,
  ProductLineSlug,
} from '@flashpack/graphql';
import { DynamicExplainer } from './DynamicExplainer/DynamicExplainer';
import { OrderDetailsMobile } from '@src/v3/order-details-v3/OrderDetailsMobile';
import { useQuery } from '@apollo/client';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

export const BookPageV3 = () => {
  const { queryParams, updateQueryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
    section: CheckoutSectionName;
  }>();

  const [payCompleted, setPayCompleted] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { section } = queryParams;
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();
  const { currentUser, loadingAuthorization } = useCustomerAuthorization();
  const { data: customerDetailsData } = useQuery(CurrentMinimalCustomerDetailsDocument, {
    skip: !currentUser,
  });
  const { basket, loading: basketLoading } = useBasket({
    skip: !currentUser,
  });

  const { isInAgeSweetspot } = useOutOfAgeRangeRedirect({
    skip: basketLoading || !basket?.reservation,
    productLineSlug: orderDetailsData?.orderDetails.productLine,
    userDateOfBirth: customerDetailsData?.currentCustomerDetails.dateOfBirth,
  });
  const isOver45Trip =
    orderDetailsData?.orderDetails.productLine === ProductLineSlug.Age_45_59;

  const paySucceeded = payCompleted || basket?.payment?.status === 'succeeded';
  const showReservationTimer = Boolean(
    currentUser && basket?.reservation && !paySucceeded,
  );

  useExpiredReservationRedirect({
    skip: !showReservationTimer,
    reservationExpired: !!basket?.reservation?.expired,
    reservationExpiresIn: basket?.reservation?.expiresIn,
    redirectBack: CheckoutRoutePath.BOOK_V3.value,
  });

  useEffect(() => {
    if (loadingAuthorization) {
      return;
    }

    if (!currentUser) {
      updateQueryParams(
        {
          toOmit: ['section'],
          preserveExisting: true,
        },
        {
          replace: true,
        },
      );
    }
  }, [currentUser, loadingAuthorization, updateQueryParams]);

  if (orderDetailsLoading || basketLoading || loadingAuthorization) {
    return (
      <Layout>
        <PageFormLoader />
      </Layout>
    );
  }

  return (
    <>
      <Layout
        hasTopPadding={!isMobile}
        displayPaymentMethods={!isMobile}
        contentSx={{
          ...(isMobile &&
            section && {
              marginTop: showReservationTimer ? '72px' : 0, // offset 72px for the sticky reservation timer when there is a section active
            }),
        }}
      >
        <ResponsiveSideBySide
          mainSection={
            <CheckoutSections
              basket={basket}
              basketLoading={basketLoading}
              isInAgeSweetspot={isInAgeSweetspot}
              isOver45Trip={isOver45Trip}
              payCompleted={payCompleted}
              setPayCompleted={setPayCompleted}
              showReservationTimer={showReservationTimer}
            />
          }
          additionalSection={
            !isMobile && (
              <Stack gap={3}>
                <OrderDetailsV3
                  sx={{ p: 4 }}
                  loadingOrderDetails={orderDetailsLoading}
                  orderDetails={orderDetailsData?.orderDetails}
                  currencyCode={queryParams.currencyCode as CustomerCurrency}
                  basket={basket}
                  showTripImage={!section}
                  loadingBasket={basketLoading}
                />
                {!isMobile && <DynamicExplainer />}
              </Stack>
            )
          }
        />
        {isMobile && (
          <OrderDetailsMobile
            loadingOrderDetails={orderDetailsLoading}
            orderDetails={orderDetailsData?.orderDetails}
            loadingBasket={basketLoading}
            basket={basket}
          />
        )}
      </Layout>
    </>
  );
};
