import { useCallback, useEffect, useRef } from 'react';

import { useRouting } from './useRouting';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

export const useExpiredReservationRedirect = ({
  skip,
  reservationExpiresIn,
  reservationExpired,
  redirectBack,
}: {
  skip?: boolean;
  reservationExpiresIn?: string;
  reservationExpired: boolean;
  redirectBack?: string;
}) => {
  const { navigate } = useRouting();
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleRedirect = useCallback(() => {
    navigate(
      CheckoutRoutePath.RESERVATION_EXPIRED.value,
      {
        preserveExisting: true,
      },
      {
        state: { redirectBack },
      },
    );
  }, [navigate, redirectBack]);

  // redirect to reservation expired page if reservation has expired
  useEffect(() => {
    if (skip || !reservationExpiresIn) {
      return;
    }

    if (reservationExpired) {
      handleRedirect();
    } else if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        handleRedirect();
      }, parseInt(reservationExpiresIn));
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [skip, navigate, reservationExpiresIn, reservationExpired, redirectBack]);
};
