import { Stack, GenericError, Skeleton, Box, Typography } from 'design-system';
import { useQuery } from '@apollo/client';
import { MyBookingsDocument } from '@flashpack/graphql';
import { TripBulletin } from './TripBulletin';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { parseISO } from 'date-fns';

export const MyTripsPage = () => {
  const { data, loading, error } = useQuery(MyBookingsDocument);

  if (loading) {
    return (
      <Stack gap={3} minHeight={'600px'}>
        <Skeleton variant="rectangular" height={140} width={'full'} animation={'pulse'} />
        <Skeleton variant="rectangular" height={140} width={'full'} animation={'pulse'} />
        <Skeleton variant="rectangular" height={140} width={'full'} animation={'pulse'} />
      </Stack>
    );
  }
  if (error) {
    return (
      <GenericError
        error={'There was an error loading your trips. Please try again or contact us.'}
      />
    );
  }

  if (data?.myBookings.length === 0) {
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="Body M">You have no trips booked yet.</Typography>
      </Box>
    );
  }
  return (
    <Stack gap={2} minHeight={'600px'}>
      {data?.myBookings.map((booking) => (
        <div key={booking.id}>
          <TripBulletin
            tripName={booking.trip.name || booking.trip.departureCode}
            startDate={parseISO(booking.trip.dateFrom)}
            endDate={parseISO(booking.trip.dateTo)}
            remainingBalance={booking.paymentInformation.remainingAmount}
            image={booking.trip.bannerImage || ''}
            link={CheckoutRoutePath.BOOKING_DETAILS.generatePath(booking.id.toString())}
          />
        </div>
      ))}
    </Stack>
  );
};
