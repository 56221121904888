import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { TripCheckoutHeroBannerDocument } from '@flashpack/graphql';
import { useRouting } from '@src/shared/hooks';
import { Skeleton } from 'design-system';
import defaultImage from '@src/shared/book-departure-header/DepartureHeaderImage.png';

export const OrderCardImage: FC = () => {
  const { queryParams } = useRouting<{
    departureCode: string;
  }>();

  const { data, loading, error } = useQuery(TripCheckoutHeroBannerDocument, {
    variables: {
      tripCode: queryParams.departureCode?.split('_')[0],
    },
    skip: !queryParams.departureCode,
  });
  if (loading) {
    return <Skeleton variant="rectangular" height={200} sx={{ borderRadius: '8px' }} />;
  }
  if (error || !data?.trip?.checkoutHeroBanner) {
    return (
      <img
        src={defaultImage}
        alt={'Trip Image'}
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          objectPosition: 'center',
          position: 'relative',
          zIndex: 1,
          borderRadius: '8px',
          marginBottom: '24px',
        }}
      />
    );
  }

  return (
    <img
      src={data?.trip?.checkoutHeroBanner}
      alt={'Trip Image'}
      style={{
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: 'center',
        position: 'relative',
        zIndex: 1,
        borderRadius: '8px',
        marginBottom: '24px',
      }}
    />
  );
};
