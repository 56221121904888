import { Box, SxProps, useMediaQuery } from '@mui/system';
import React, { ReactNode } from 'react';
import Header from '../Header/Header';
import { useTheme } from 'design-system';
import { PaymentIcons } from '@src/shared/PaymentIcons/PaymentIcons';

interface LayoutProps {
  children: ReactNode;
  HeroComponent?: ReactNode;
  hasTopPadding?: boolean;
  displayPaymentMethods?: boolean;
  contentSx?: SxProps;
}

/**
 * A responsive layout padding that can be reused across global UI components
 * to make an effect of horizontal alignment.
 *
 * E.g.:
 * [   ] Page header  [   ]
 * [   ] Page content [   ]
 * [   ] Page footer  [   ]
 *   ^__________________^_________ shared padding
 */
export const pageHorizontalPadding = {
  xs: 2,
  sm: 3,
  md: 3,
  lg: 3,
} as const;

export const pageMaxWidthPx = 1240;

const Layout: React.FC<LayoutProps> = (props) => {
  const {
    children,
    HeroComponent,
    hasTopPadding = true,
    displayPaymentMethods = false,
    contentSx,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {/* offset 74 for the sticky header which would cover the content*/}
      {isMobile && <Box height="74px" />}{' '}
      <Header displayPaymentMethods={displayPaymentMethods} />
      {displayPaymentMethods && (
        <Box display={'flex'} justifyContent={'center'} marginBottom={5}>
          <PaymentIcons />
        </Box>
      )}
      {HeroComponent}
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          px: pageHorizontalPadding,
          paddingTop: hasTopPadding ? '20px' : 0,
          paddingBottom: '80px', // change it when footer is added
          maxWidth: 1240,
          marginBottom: '80px',
          ...contentSx,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
