import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CheckCustomerEmailValidityDocument,
  CreateMinimalCustomerDocument,
  LoginWithEmailAndPasswordDocument,
} from '@flashpack/graphql';
import { useFirebaseAuth } from '@src/auth/useAuthentication';
import { GenderDropdown } from '@src/shared/GenderDropdown/GenderDropdown';
import { useRouting } from '@src/shared/hooks';
import { format } from 'date-fns';
import {
  composeValidators,
  FormDateInput,
  FormTextInput,
  GenericError,
  Grid,
  LoadingButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Validator,
} from 'design-system';
import { Form } from 'react-final-form';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { useState, useEffect } from 'react';

interface CreateAccountFormData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
}

export const CreateAccountSection = () => {
  const [isLogin, setIsLogin] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction="column" gap={1} alignItems="flex-start">
      <Typography variant="Body S" sx={{ mt: isMobile ? 2 : 3, mr: isMobile ? 0 : 5 }}>
        {isLogin
          ? 'Welcome back! Please log in to your account. This will hold your spot for 25 minutes.'
          : 'We need some basic account information to hold your spot on this trip. This will hold your spot for 25 minutes.'}
      </Typography>
      <Typography
        variant="Body S"
        sx={{
          width: '100%',
          backgroundColor: theme.palette.system.blueLight,
          py: 1.5,
          px: 2,
          mb: isMobile ? 0 : 3,
        }}
      >
        {isLogin ? "Don't have an account?" : 'Already have an account?'}{' '}
        <span
          data-testid={isLogin ? 'signup-link' : 'login-link'}
          onClick={() => setIsLogin(!isLogin)}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'inherit',
          }}
        >
          {isLogin ? 'Sign up' : 'Sign in'}
        </span>
      </Typography>

      {isLogin ? <LoginForm /> : <SignUpForm setIsLoginView={setIsLogin} />}
    </Stack>
  );
};

const formatValues = (values: CreateAccountFormData) => {
  const date = new Date(values.dateOfBirth);

  const formattedValues = {
    ...values,
    dateOfBirth: format(date, 'yyyy-MM-dd HH:mm:ss'),
  };

  return formattedValues;
};

type SignUpFormProps = {
  setIsLoginView: (isLoginView: boolean) => void;
};

const SignUpForm = ({ setIsLoginView }: SignUpFormProps) => {
  const [checkEmailExists] = useLazyQuery(CheckCustomerEmailValidityDocument);
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);
  const validateEmail = async (value: string) => {
    if (value && Validator.validEmail(value)) {
      return Validator.validEmail(value);
    }
    if (Validator.required(value)) {
      return Validator.required(value);
    }

    const { data, error } = await checkEmailExists({
      variables: {
        input: {
          email: value,
        },
      },
    });
    if (error) {
      return undefined;
    }
    if (!data?.checkCustomerEmailValidity.valid) {
      return (
        <>
          We can&apos;t create an account with this email address. If you already have an
          account, please{' '}
          <span
            onClick={() => setIsLoginView(true)}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'inherit',
            }}
          >
            sign in
          </span>
          .
        </>
      );
    }
  };
  const { signInWithEmailAndPassword } = useFirebaseAuth();
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const [createCustomer, { error: createCustomerError }] = useMutation(
    CreateMinimalCustomerDocument,
  );

  useEffect(() => {
    if (createCustomerError && !error) {
      if (createCustomerError.networkError) {
        setError(
          'A temporary error occurred while creating your account. Please try again later.',
        );
      }
      setError(createCustomerError.message);
    }
  }, [createCustomerError, error]);

  const createAccount = async (values: CreateAccountFormData) => {
    const formattedValues = formatValues(values);

    await createCustomer({
      variables: {
        input: {
          ...formattedValues,
        },
      },
    });

    await signInWithEmailAndPassword(
      { email: values.email, password: values.password },
      {
        onError: (e) => {
          setError(e.message);
        },
      },
    );

    updateQueryParams({
      toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
      preserveExisting: true,
    });
  };
  return (
    <Form<CreateAccountFormData>
      initialValues={{}}
      onSubmit={(values) => {
        return createAccount(values);
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit();
            }}
          >
            <Grid
              sx={{ width: '100%', mb: 3 }}
              container
              rowSpacing={2}
              columnSpacing={3}
            >
              <Grid item xs={12} sm={6}>
                <FormTextInput
                  name="firstName"
                  validate={Validator.required}
                  validateFields={[]}
                  textInputProps={{
                    size: 'fullWidth',
                    id: 'user-first-name',
                    autoComplete: 'given-name',
                    placeholder: 'Enter first name...',
                    label: 'First Name *',
                    testid: 'create-account-first-name',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextInput
                  name="lastName"
                  validateFields={[]}
                  textInputProps={{
                    id: 'user-family-name',
                    autoComplete: 'family-name',
                    placeholder: 'Enter last name...',
                    label: 'Last Name *',
                    testid: 'create-account-last-name',
                    size: 'fullWidth',
                  }}
                  validate={Validator.required}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextInput
                  name="email"
                  validateFields={[]}
                  textInputProps={{
                    id: 'user-email',
                    placeholder: 'Enter email address...',
                    label: 'Email Address *',
                    testid: 'create-account-email',
                    size: 'fullWidth',
                    autoComplete: 'email',
                  }}
                  validate={validateEmail}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextInput
                  name="password"
                  validateFields={[]}
                  textInputProps={{
                    placeholder: 'Enter new password...',
                    label: 'Create Password *',
                    testid: 'create-account-password',
                    type: 'password',
                    size: 'fullWidth',
                    autoComplete: 'new-password',
                  }}
                  validate={composeValidators(
                    Validator.required,
                    Validator.validPassword,
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GenderDropdown validateFields={[]} size="medium" label="Gender *" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDateInput
                  name="dateOfBirth"
                  validateFields={[]}
                  omitLabels={true}
                  label="Date of Birth *"
                  testid="create-account-date-of-birth"
                  inputProps={{
                    id: 'user-date-of-birth',
                  }}
                  dayInputProps={{
                    id: 'user-date-of-birth-day',
                    name: 'bday-day',
                    autoComplete: 'bday-day',
                  }}
                  monthInputProps={{
                    id: 'user-date-of-birth-month',
                    name: 'bday-month',
                    autoComplete: 'nope', // 'bday-month',
                  }}
                  yearInputProps={{
                    id: 'user-date-of-birth-year',
                    name: 'bday-year',
                    autoComplete: 'bday-year',
                  }}
                  validate={composeValidators(
                    Validator.required,
                    Validator.dateIsInFlashpackerRange,
                  )}
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              loading={submitting}
              variant="contained"
              fullWidth={false}
              sx={{ backgroundColor: theme.palette.principal.black }}
              onClick={() => void handleSubmit()}
            >
              Hold my spot
            </LoadingButton>
            {error && <GenericError error={error} />}
          </form>
        );
      }}
    />
  );
};

type LogInFormData = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const [loginUser, { error: loginError }] = useMutation(
    LoginWithEmailAndPasswordDocument,
  );
  const [error, setError] = useState<string | null>(null);
  const { signInWithEmailAndPassword } = useFirebaseAuth();
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();

  useEffect(() => {
    if (loginError && !error) {
      if (loginError.networkError) {
        setError(
          'A temporary error occurred while creating your account. Please try again later.',
        );
      }
      setError(loginError.message);
    }
  }, [loginError, error]);

  const theme = useTheme();
  const handleLogin = async (values: LogInFormData) => {
    const { email, password } = values;
    const { data } = await loginUser({
      variables: {
        email,
        password,
      },
    });
    if (data?.loginWithEmailAndPassword) {
      await signInWithEmailAndPassword({
        email,
        password,
      });
      updateQueryParams({
        toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
        preserveExisting: true,
      });
    }
  };
  return (
    <Form<LogInFormData>
      initialValues={{}}
      onSubmit={handleLogin}
      render={({ handleSubmit, submitting }) => (
        <>
          <Grid sx={{ width: '100%', mb: 3 }} container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormTextInput
                name="email"
                validate={composeValidators(Validator.required, Validator.validEmail)}
                textInputProps={{
                  size: 'fullWidth',
                  placeholder: 'Enter email...',
                  label: 'Email Address',
                  testid: 'login-email',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <FormTextInput
                name="password"
                validate={Validator.required}
                textInputProps={{
                  size: 'fullWidth',
                  placeholder: 'Enter password...',
                  label: 'Password',
                  testid: 'login-password',
                  type: 'password',
                }}
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={submitting}
            variant="contained"
            fullWidth={false}
            sx={{ backgroundColor: theme.palette.principal.black }}
            onClick={() => void handleSubmit()}
          >
            Login & Continue
          </LoadingButton>
          {error && <GenericError error={error} />}
        </>
      )}
    />
  );
};
