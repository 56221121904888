import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  WarningIcon,
} from 'design-system';
import {
  TermsScrollablePart,
  TermsWrapper,
  TermsContent,
} from './BookingTermsAndConditions.styled';

interface PropTypes {
  termsAndConditionsHTML: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  name?: string;
  error?: string | JSX.Element;
  touched?: boolean;
}

export const BookingTermsAndConditions: React.FC<PropTypes> = ({
  termsAndConditionsHTML,
  checked,
  onChange,
  onFocus,
  onBlur,
  name = 'acceptedTermsAndConditions',
  error,
  touched,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack gap={2}>
      <Typography variant={isMobile ? 'Body M bold' : 'H5'}>
        Booking terms & conditions
      </Typography>
      <TermsWrapper>
        <TermsScrollablePart>
          <TermsContent dangerouslySetInnerHTML={{ __html: termsAndConditionsHTML }} />
        </TermsScrollablePart>
      </TermsWrapper>
      <Box sx={{ minHeight: '83px' }}>
        <FormControlLabel
          label={
            <Typography variant={'Body S'}>
              I have read, understand and agree to the terms and conditions of sale
            </Typography>
          }
          checked={checked}
          name={name}
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={!!error && touched}
              data-testid="agree-to-terms"
              sx={{ marginLeft: '3px' }}
            />
          }
        />
        {touched && error && (
          <Stack mt={1} ml={0} flexDirection={'row'} gap={1} color="system.red100">
            <WarningIcon fontSize="small" />
            <Typography variant="Body S">
              Please tick this box if you want to proceed
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
