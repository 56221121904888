import { Typography } from '@mui/material';

import { Box, useTheme } from 'design-system';

export const AgeSweetspotBanner = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: '#EB8E0C',
      }}
      data-testid="age-sweetspot-banner"
    >
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          px: {
            xs: 2,
            sm: 3,
            md: 3,
            lg: 3,
          },
          py: 1,
          maxWidth: 1140,
        }}
      >
        <Typography variant="Body M" color={theme.palette.principal.white}>
          This trip is only for 45-59 year olds. Looking for 30s and 40s trips -{' '}
          <a
            href="https://www.flashpack.com/adventure-trips/"
            style={{
              color: theme.palette.principal.white,
              cursor: 'pointer',
              position: 'relative',
            }}
          >
            Click here
          </a>
        </Typography>
      </Box>
    </Box>
  );
};
