import { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  FormTextInput,
  Link,
  LoadingButton,
  Validator,
  composeValidators,
} from 'design-system';
import { Stack, Typography } from '@mui/material';
import Layout from '@src/shared/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '@src/auth/useAuthentication';
import { UpdateCheckoutAccountPasswordDocument } from '@flashpack/graphql';
import { useMutation } from '@apollo/client';
import { useRouting } from '@src/shared/hooks';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

type UpdatePasswordForm = {
  password: string;
  confirmPassword: string;
};

export const UpdatePasswordPage = () => {
  const [error, setError] = useState<string | null>(null);
  const [validOobCode, setValidOobCode] = useState(false);
  const [updatePassword] = useMutation(UpdateCheckoutAccountPasswordDocument);
  const navigate = useNavigate();
  const { queryParams } = useRouting<{
    oobCode: string;
    email: string;
    redirectUrl: string;
  }>();
  const { confirmPasswordReset, verifyPasswordResetCode, signInWithEmailAndPassword } =
    useFirebaseAuth();

  useEffect(() => {
    const code = queryParams.oobCode;
    if (!code) {
      setError('Invalid password reset link');
      return;
    }
    verifyPasswordResetCode(code)
      .then(() => setValidOobCode(true))
      .catch(() => setError('This password reset link has expired or is invalid'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({ password }: UpdatePasswordForm) => {
    const code = queryParams.oobCode;
    if (!code) {
      return;
    }

    try {
      await confirmPasswordReset(code, password);

      await signInWithEmailAndPassword(
        { email: decodeURIComponent(queryParams.email), password },
        {
          onSuccess: () => {
            updatePassword({
              variables: {
                input: {
                  password: password,
                },
              },
            })
              .then(() => {
                navigate(decodeURIComponent(queryParams.redirectUrl));
              })
              .catch((e) => {
                console.log(e);
                setError('Failed to update password. Please try again.');
              });
          },

          onError: (e) => {
            setError(e.message);
          },
        },
      );
    } catch (e) {
      setError('Failed to update password. Please try again.');
    }
  };

  if (error) {
    return (
      <Layout>
        <Stack gap={3} minHeight="50vh" mt={2}>
          <Typography variant="H3">Update Password</Typography>
          <Stack gap={1}>
            <Typography variant="Body M">{error}</Typography>
            <Typography variant="Body M">
              You can reset your password{' '}
              <Link href={CheckoutRoutePath.LOGIN.value}>here.</Link>
            </Typography>
          </Stack>
        </Stack>
      </Layout>
    );
  }

  if (!validOobCode) {
    return null;
  }

  return (
    <Layout>
      <Stack gap={3} minHeight="50vh" mt={2}>
        <Typography variant="H3">Create new password</Typography>
        <Form<UpdatePasswordForm>
          onSubmit={onSubmit}
          render={({ handleSubmit, values, submitting }) => (
            <form
              onSubmit={(e) => {
                void handleSubmit();
                e?.preventDefault();
              }}
            >
              <Stack gap={2}>
                <FormTextInput
                  name="password"
                  validateFields={[]}
                  textInputProps={{
                    placeholder: 'Enter new password...',
                    label: 'New Password',
                    testid: 'new-password',
                    type: 'password',
                    size: 'large',
                  }}
                  validate={composeValidators(
                    Validator.required,
                    Validator.validPassword,
                  )}
                />
                <FormTextInput
                  name="confirmPassword"
                  validateFields={[]}
                  textInputProps={{
                    placeholder: 'Confirm new password...',
                    label: 'Confirm Password',
                    testid: 'confirm-password',
                    type: 'password',
                    size: 'large',
                  }}
                  validate={composeValidators(Validator.required, (value) => {
                    if (value !== values.password) {
                      return 'Passwords do not match';
                    }
                    return undefined;
                  })}
                />
                <LoadingButton
                  loading={submitting}
                  disabled={submitting}
                  type="submit"
                  variant="contained"
                  data-testid="update-password-button"
                  sx={{ width: '200px' }}
                >
                  {submitting ? 'Updating...' : 'Update Password'}
                </LoadingButton>
              </Stack>
            </form>
          )}
        />
      </Stack>
    </Layout>
  );
};
