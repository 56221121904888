import { FC, useCallback, useState } from 'react';
import { OrderDetailsNote } from '@flashpack/graphql';
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Box, Stack, Typography, InformationIcon } from 'design-system';
type Props = {
  tripNote?: OrderDetailsNote | null;
};

export const TripDisclaimer: FC<Props> = ({ tripNote }) => {
  const theme = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isCompactView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTooltipToggle = useCallback(() => {
    setIsTooltipOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsTooltipOpen(true);
  }, []);

  if (!tripNote || !tripNote.label) {
    return null;
  }

  return (
    <Stack mb={2}>
      <Typography
        variant="Additional"
        color={theme.palette.text.secondary}
        sx={{ mb: 0.5 }}
      >
        Trip Information
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
        <Typography variant="Body S">{tripNote.label}</Typography>
        {tripNote.content && (
          <ClickAwayListener onClickAway={isTooltipOpen ? handleClose : () => {}}>
            <Tooltip
              disableFocusListener={isCompactView}
              disableHoverListener={isCompactView}
              disableTouchListener={isCompactView}
              title={tripNote.content}
              arrow
              open={isTooltipOpen}
              onClose={handleClose}
              onOpen={handleOpen}
            >
              <Box onClick={isCompactView ? handleTooltipToggle : () => {}}>
                <InformationIcon
                  sx={{
                    fill: theme.palette.principal.black,
                    width: 18,
                    height: 18,
                    mt: 0.5,
                  }}
                />
              </Box>
            </Tooltip>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
