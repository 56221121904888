import React from 'react';
import {
  Basket,
  CustomerCurrency,
  PaymentOptionType,
  SelectedPaymentOption,
} from '@flashpack/graphql';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import {
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from 'design-system';
import { formatDateToOrdinalMonthYear } from '../shared/dateUtils';

interface PropTypes {
  selectedPaymentOption: SelectedPaymentOption | null | undefined;
  finalCost?: number | null;
  warningMessage?: string;
  currency: CustomerCurrency;
  basket?: Basket;
}

export type TotalToPayProps = PropTypes;

/**
 * A component that displays only the final cost
 * with an optional warning message regarding it
 */
export const TotalToPay: React.FC<PropTypes> = (props) => {
  const { finalCost, selectedPaymentOption, currency } = props;
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!finalCost) {
    return (
      <Stack
        gap={2}
        sx={{
          borderRadius: 4,
          backgroundColor: theme.palette.system.blue10,
          padding: 2,
        }}
      >
        <Skeleton />
      </Stack>
    );
  }

  return (
    <Stack
      gap={2}
      sx={{
        borderRadius: 4,
        backgroundColor: theme.palette.system.blue10,
        padding: isSmallScreen ? 2 : 3,
      }}
    >
      {selectedPaymentOption?.optionType === PaymentOptionType.FullBalance && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography
            typography={{
              xs: 'H6',
              sm: 'H5',
            }}
          >
            Trip total
          </Typography>
          <Typography
            typography={{
              xs: 'H6',
              sm: 'H5',
            }}
          >
            {formatCurrency(finalCost, currency)}
          </Typography>
        </Stack>
      )}

      {selectedPaymentOption?.optionType === PaymentOptionType.BookNowPayLater && (
        <Stack gap={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography
              typography={{
                xs: 'H6',
                sm: 'H4',
              }}
            >
              To pay today
            </Typography>
            <Typography
              typography={{
                xs: 'H6',
                sm: 'H4',
              }}
              data-testid="to-pay-today"
            >
              {formatCurrency(finalCost, currency)}
            </Typography>
          </Stack>

          {selectedPaymentOption.leftToPay && !isSmallScreen && (
            <>
              <Divider sx={{ m: 0, borderColor: 'principal.white' }} />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack gap={0.5}>
                  <Typography variant="Body L">Left to pay</Typography>
                  <Typography variant="Additional">
                    Will be automatically taken on{' '}
                    {formatDateToOrdinalMonthYear(
                      selectedPaymentOption.leftToPay.dateToPay,
                    )}
                  </Typography>
                </Stack>
                <Typography variant="Body L" data-testid="left-to-pay">
                  {formatCurrency(selectedPaymentOption.leftToPay.amount, currency)}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      )}

      {(selectedPaymentOption?.optionType === PaymentOptionType.ThreeMonthsPlan ||
        selectedPaymentOption?.optionType === PaymentOptionType.SixMonthsPlan) && (
        <>
          <Stack gap={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography
                variant="H4"
                typography={{
                  xs: 'H5',
                  sm: 'H4',
                }}
              >
                To pay today
              </Typography>
              <Typography
                variant="H4"
                typography={{
                  xs: 'H5',
                  sm: 'H4',
                }}
              >
                {formatCurrency(finalCost, currency)}
              </Typography>
            </Stack>

            {selectedPaymentOption.leftToPay && !isSmallScreen && (
              <>
                {/* <Divider sx={{ m: 0, borderColor: 'principal.white' }} /> */}
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="Body L">Left to pay</Typography>

                  <Typography variant="Body L" data-testid="left-to-pay">
                    {formatCurrency(selectedPaymentOption.leftToPay.amount, currency)}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
