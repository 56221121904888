import { Basket } from '@flashpack/graphql';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { Extras } from '@src/shared/extras/Extras';
import { useRouting } from '@src/shared/hooks';
import { QuoteCard } from '@src/shared/RoomHesitation/QuoteCard';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { Box, Button, useMediaQuery, useTheme } from 'design-system';
import melissaCapocciImage from '@src/shared/RoomHesitation/melissa-capocci.png';

export const PersonaliseBookingSection = ({
  completed,
  onComplete,
  basket,
  activeSection,
  editable,
  showReservationTimer,
}: {
  activeSection: CheckoutSectionName;
  completed: boolean;
  onComplete: (sectionName: CheckoutSectionName) => void;
  basket: Basket | null;
  editable: boolean;
  showReservationTimer: boolean;
}) => {
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CheckoutExpandableSection
      title="Personalise your booking"
      topDivider={isMobile ? false : showReservationTimer}
      open={activeSection === CheckoutSectionName.PersonaliseBookingSection}
      completed={completed}
      editable={editable}
      onEdit={() => {
        updateQueryParams(
          {
            preserveExisting: true,
            toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
          },
          { replace: true },
        );
      }}
    >
      {basket && (
        <Box mt={isMobile ? 2 : 3} mb={2}>
          <Extras
            displayTitle={false}
            basket={basket}
            infoMessage="All extras are subject to availability"
          />
        </Box>
      )}
      {isMobile && (
        <QuoteCard
          text="Sharing a room was a wonderful thing. Flash Pack does a great job of getting to know their people beforehand and paired us based on that understanding."
          title="Our average room-mate matching score is 8.6 out of 10"
          author="Melissa Capocci"
          authorImage={melissaCapocciImage}
        />
      )}
      <Button
        variant="contained"
        sx={{
          alignSelf: 'flex-start',
          backgroundColor: theme.palette.principal.black,
          mt: 3,
        }}
        onClick={() => {
          onComplete(CheckoutSectionName.PersonaliseBookingSection);
        }}
      >
        Save & continue
      </Button>
    </CheckoutExpandableSection>
  );
};
