import React from 'react';
import { Typography, styled, Stack, Link, Avatar } from '@mui/material';
import FormatQuoteIcon from './FormatQuoteIcon';
import { Box, useMediaQuery } from '@mui/system';
import { useTheme } from 'design-system';

export interface Quote {
  text: string;
  title?: string;
  author?: string;
  authorSocialUrl?: string;
  authorImage?: string;
}

export const QuoteCard: React.FC<Quote> = ({
  text,
  title,
  author,
  authorSocialUrl,
  authorImage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <QuoteContainer sx={{ padding: isMobile ? 2 : 4 }}>
      <Stack spacing={isMobile ? 1 : 2}>
        {title && (
          <Typography variant={isMobile ? 'Body M bold' : 'H5'}>{title}</Typography>
        )}
        <QuoteTextContainer>
          <FormatQuoteIcon sx={{ fontSize: 22 }} />
          <Stack gap={isMobile ? 1 : 2}>
            <Typography variant={isMobile ? 'Body S' : 'Body M'}>{text}</Typography>
            {(author || authorImage) && (
              <AuthorLink
                href={authorSocialUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AuthorContainer>
                  {authorImage && (
                    <Avatar
                      src={authorImage}
                      alt={author || 'Author'}
                      sx={{ width: 24, height: 24 }}
                    />
                  )}
                  {author && <AuthorText>{author}</AuthorText>}
                </AuthorContainer>
              </AuthorLink>
            )}
          </Stack>
        </QuoteTextContainer>
      </Stack>
    </QuoteContainer>
  );
};

export const QuoteContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.principal.grey30,
  gap: theme.spacing(3),
  minWidth: 300,
}));

const QuoteTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'start',
  gap: theme.spacing(2),
}));

const AuthorLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

const AuthorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const AuthorText = styled(Typography)(({ theme }) => ({
  ...theme.typography['Body S'],
  color: theme.palette.principal.grey70,
}));
