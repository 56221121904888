import React, { useState, useEffect } from 'react';
import { useRouting } from '@src/shared/hooks';
import {
  Box,
  Button,
  CommentsIcon,
  KeysIcon,
  Stack,
  Typography,
  useTheme,
  styled,
  useMediaQuery,
} from 'design-system';
import { CheckoutSectionName } from '../checkout-sections/CheckoutSections';
import { QuoteCard, QuoteContainer } from '@src/shared/RoomHesitation/QuoteCard';
import melissaCapocciImage from '@src/shared/RoomHesitation/melissa-capocci.png';

enum Section {
  SPEAK_TO_AN_EXPERT = 'speak_to_an_expert',
  ROOM_MATE_MATCHING = 'room_mate_matching',
  MONEY_IS_SAFE = 'money_is_safe',
  HAVING_PROBLEMS_PAYING = 'having_problems_paying',
}

const AnimatedSection = styled(Box)<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
`;

export const DynamicExplainer: React.FC = () => {
  const { queryParams } = useRouting<{ section: CheckoutSectionName }>();
  const [currentSection, setCurrentSection] = useState<Section>(
    parseSectionFromQueryParam(queryParams.section),
  );
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (queryParams.section) {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentSection(parseSectionFromQueryParam(queryParams.section));
        setIsVisible(true);
      }, 100);
    }
  }, [queryParams.section]);

  const renderSectionContent = (section: Section) => {
    switch (section) {
      case Section.SPEAK_TO_AN_EXPERT:
        return <SpeakToAnExpert />;
      case Section.ROOM_MATE_MATCHING:
        return <RoomMateMatching />;
      case Section.MONEY_IS_SAFE:
        return <MoneyIsSafeQuote />;
      case Section.HAVING_PROBLEMS_PAYING:
        return <HavingProblemsPayingQuote />;
      default:
        return null;
    }
  };

  return (
    <AnimatedSection $isVisible={isVisible}>
      {renderSectionContent(currentSection)}
    </AnimatedSection>
  );
};

const parseSectionFromQueryParam = (sectionQueryParam: CheckoutSectionName): Section => {
  switch (sectionQueryParam) {
    case CheckoutSectionName.CreateAccountSection:
      return Section.SPEAK_TO_AN_EXPERT;
    case CheckoutSectionName.PersonaliseBookingSection:
      return Section.ROOM_MATE_MATCHING;
    case CheckoutSectionName.PaymentOptionsSection:
      return Section.MONEY_IS_SAFE;
    case CheckoutSectionName.PaySection:
      return Section.HAVING_PROBLEMS_PAYING;
    default:
      return Section.SPEAK_TO_AN_EXPERT;
  }
};

const SpeakToAnExpert: React.FC = () => {
  const theme = useTheme();
  return (
    <QuoteContainer>
      <Stack gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <CommentsIcon
            sx={{
              width: 40,
              height: 40,
              color: 'transparent',
              stroke: theme.palette.principal.black,
            }}
          />

          <Typography variant="H4">Speak to an expert</Typography>
        </Box>
        <Typography variant="Body S">
          If you still have questions about your perfect trip, we’re here to help.
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => {
            if (window.Intercom) {
              window.Intercom('show');
            } else {
              window.open('https://www.flashpack.com/contact-flash-pack/', '_blank');
            }
          }}
        >
          Contact us
        </Button>
      </Stack>
    </QuoteContainer>
  );
};

const RoomMateMatching: React.FC = () => {
  return (
    <QuoteCard
      text="Sharing a room was a wonderful thing. Flash Pack does a great job of getting to know their people beforehand and paired us based on that understanding."
      title="Our average room-mate matching score is 8.6 out of 10"
      author="Melissa Capocci"
      authorImage={melissaCapocciImage}
    />
  );
};

export const MoneyIsSafeQuote: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <QuoteContainer sx={{ backgroundColor: theme.palette.system.greenLight }}>
      <Stack gap={isMobile ? 1 : 3}>
        <Box display="flex" alignItems="center" gap={1}>
          <KeysIcon sx={{ width: isMobile ? 20 : 40, height: isMobile ? 20 : 40 }} />
          <Typography variant={isMobile ? 'Body M bold' : 'H4'}>
            Your money is safe
          </Typography>
        </Box>
        <Stack gap={2}>
          <Typography variant={isMobile ? 'Body S' : 'Body M'}>
            In the unlikely event your trip doesn’t run, we’ve got you covered:{' '}
          </Typography>
          <Typography variant={isMobile ? 'Body S' : 'Body M'}>
            <ul style={{ listStyle: 'disc', paddingLeft: '1rem' }}>
              <li style={{ marginBottom: '0.5rem' }}>
                <span style={{ fontWeight: 600 }}>Cost-free transfer</span> to another
                date or another trip of equal value.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>A full refund</span> within 14 days.
              </li>
            </ul>
          </Typography>
        </Stack>
      </Stack>
    </QuoteContainer>
  );
};

export const HavingProblemsPayingQuote: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <QuoteContainer>
      <Stack gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <CommentsIcon
            sx={{
              width: isMobile ? 20 : 40,
              height: isMobile ? 20 : 40,
              color: 'transparent',
              stroke: theme.palette.principal.black,
            }}
          />

          <Typography variant={isMobile ? 'Body M bold' : 'H4'}>
            Having problems paying?
          </Typography>
        </Box>
        <Typography variant="Body S">
          If you still have questions about your perfect trip, we’re here to help.
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => {
            if (window.Intercom) {
              window.Intercom('show');
            } else {
              window.open('https://www.flashpack.com/contact-flash-pack/', '_blank');
            }
          }}
        >
          Contact us
        </Button>
      </Stack>
    </QuoteContainer>
  );
};
