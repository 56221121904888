import React, { useState } from 'react';
import { Box, Skeleton, Stack, TickIcon, Typography, useTheme } from 'design-system';
import { DontBookFlightsYet } from './DontBookFlightsYet';
import { JoinTripsWhatsapp } from './JoinTripsWhatsapp';
import { DownloadFlashPackApp } from './DownloadFlashPackApp';
import Layout from '@src/shared/Layout/Layout';
import { useOrderDetails, useRouting } from '@src/shared/hooks';
import {
  CurrentBasketDocument,
  CurrentCustomerDetailsDocument,
  CustomerCurrency,
  TripDepartureStatus,
  UpdateBasketJoinWhatsAppGroupDocument,
  UpdateCustomerPhoneNumberDocument,
} from '@flashpack/graphql';
import getCallingCode from 'libphonenumber-js';
import { useMutation, useQuery } from '@apollo/client';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';

export const YourTripIsBooked: React.FC = () => {
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const theme = useTheme();

  const { currentUser, loadingAuthorization } = useCustomerAuthorization();
  const { data: currentCustomerDetailsData, loading: currentCustomerDetailsLoading } =
    useQuery(CurrentCustomerDetailsDocument, {
      skip: !currentUser,
    });
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const { departureCode, currencyCode } = queryParams;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;
  const [updateCustomerPhoneNumber] = useMutation(UpdateCustomerPhoneNumberDocument);
  const [updateBasketJoinWhatsAppGroup] = useMutation(
    UpdateBasketJoinWhatsAppGroupDocument,
  );

  const { data: basketData, loading: loadingBasket } = useQuery(CurrentBasketDocument, {
    variables: {
      input: {
        departureCode,
        currency: customerCurrency,
      },
    },
    skip: !currentUser,
  });

  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const isMobile = theme.breakpoints.down('sm');

  const tripStatus = orderDetailsData?.orderDetails.tripStatus;
  const isTripGuaranteed = tripStatus === TripDepartureStatus.Guaranteed;

  const onJoinWhatsAppGroup = async (phoneNumber: string) => {
    const callingCode = getCallingCode(phoneNumber);
    if (!basketData?.currentBasket) {
      throw new Error('Basket not yet loaded');
    }
    if (!callingCode) {
      throw new Error('Invalid phone number calling code');
    }
    await Promise.all([
      updateBasketJoinWhatsAppGroup({
        variables: {
          input: { id: basketData?.currentBasket.id, joinWhatsAppGroup: true },
        },
      }),
      updateCustomerPhoneNumber({
        variables: {
          input: {
            phoneCountryCode: callingCode.countryCallingCode,
            phoneNumber: phoneNumber,
          },
        },
      }),
    ]);
    setPhoneNumberSubmitted(true);
  };

  if (
    loadingAuthorization ||
    loadingBasket ||
    orderDetailsLoading ||
    currentCustomerDetailsLoading
  ) {
    return (
      <Layout>
        <Skeleton variant="rounded" width="100%" height={94} sx={{ mb: 5 }} />
        <Skeleton variant="rounded" height={547} />
      </Layout>
    );
  }

  return (
    <Layout contentSx={{ ...(isMobile && { px: 0 }) }}>
      <Stack mb={5} gap={isMobile ? 3 : 5} sx={{ px: 3 }} alignItems="center">
        <TickIcon
          fill={theme.palette.principal.black}
          sx={{
            width: {
              xs: '70px',
              sm: '80px',
            },
            height: {
              xs: '70px',
              sm: '80px',
            },
          }}
        />

        <Typography
          textAlign="center"
          typography={{ xs: 'H4', md: 'H1 title' }}
          fontSize={{ xs: '26px', lg: '56px' }}
          width={{ xs: '100%', md: 840 }}
        >
          Your trip to {orderDetailsData?.orderDetails.location} is booked! Here are your
          next steps...
        </Typography>
      </Stack>
      <Stack alignItems="center">
        <Box
          sx={{
            width: {
              xs: '100%',
              md: 700,
            },
            borderRadius: '8px',
            py: 5,
            px: isMobile ? 3 : 5,
            backgroundColor: '#FDE5C5',
          }}
        >
          {isTripGuaranteed ? null : <DontBookFlightsYet />}

          <JoinTripsWhatsapp
            userDefaultPhoneNumber={
              currentCustomerDetailsData?.currentCustomerDetails?.phoneNumber || undefined
            }
            onPhoneNumberSubmit={onJoinWhatsAppGroup}
            activeSection={
              phoneNumberSubmitted
                ? 'join-whatsapp-success'
                : 'join-whatsapp-phone-number-prompt'
            }
          />

          <DownloadFlashPackApp />
        </Box>
      </Stack>
    </Layout>
  );
};
