import * as Sentry from '@sentry/react';

export const triggerConvertConversion = () => {
  try {
    // Initialize the queue if it doesn't exist
    window._conv_q = window._conv_q || [];
    // Push the conversion trigger with your specific ID
    window._conv_q.push(['triggerConversion', '100476204']);
  } catch (error) {
    Sentry.captureException(error);
  }
};

// Add TypeScript types
declare global {
  interface Window {
    _conv_q: Array<['triggerConversion', string]>;
  }
}
