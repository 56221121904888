import ReactDOM from 'react-dom/client';
import App from '@src/App';
import { initSentry } from './sentry';
import { Environment, environmentIs } from '@src/environment';
import { initClarity } from './clarity';

initSentry();

const root = document.getElementById('root');

// Add Clarity script for sandbox environment
if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'sandbox') {
  console.log('Loading Clarity for sandbox environment');
  initClarity();
}

if (root) {
  if (environmentIs(Environment.Test)) {
    ReactDOM.createRoot(root).render(<App />);
  } else {
    ReactDOM.createRoot(root).render(<App />);
  }
}
