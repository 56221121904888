import React from 'react';
import { HeroHeader } from '@src/shared/hero-header/HeroHeader';
import { TripCheckoutHeroBannerDocument } from '@flashpack/graphql';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useRouting } from '../hooks';
interface BookDepartureHeaderProps {
  textAlign?: 'left' | 'center' | 'right';
}

/**
 * Either the title or the location prop must be provided.
 */
type ConditionalProps =
  | {
      title: React.ReactNode;
      location?: never;
    }
  | {
      title?: never;
      location?: string;
    };

const BookDepartureHeader: React.FC<BookDepartureHeaderProps & ConditionalProps> = ({
  title,
  location,
  textAlign = 'left',
}) => {
  const { queryParams } = useRouting<{
    departureCode: string;
  }>();

  const { data, loading, error } = useQuery(TripCheckoutHeroBannerDocument, {
    variables: {
      tripCode: queryParams.departureCode?.split('_')[0],
    },
    skip: !queryParams.departureCode,
  });

  const resolvedTitle = title || createBookDepartureHeaderTitle(location);
  if (loading) {
    return <HeroHeader text={resolvedTitle} textAlign={textAlign} loading={loading} />;
  }

  if (error || !data?.trip?.checkoutHeroBanner) {
    Sentry.captureException('Error loading trip checkout hero banner', {
      level: 'warning',
      extra: {
        departureCode: queryParams.departureCode,
      },
    });
    return <HeroHeader text={resolvedTitle} textAlign={textAlign} />;
  }

  return (
    <HeroHeader
      text={resolvedTitle}
      textAlign={textAlign}
      image={data?.trip?.checkoutHeroBanner}
    />
  );
};

const createBookDepartureHeaderTitle = (location?: string) => {
  if (!location) {
    return null;
  }

  return (
    <>
      Book your adventure
      <br />
      to {location || ''}
    </>
  );
};

export default BookDepartureHeader;
