import { ApolloError, useQuery } from '@apollo/client';
import {
  Basket,
  CustomerCurrency,
  OrderDetails,
  TripCheckoutHeroBannerDocument,
  TripCheckoutHeroBannerQuery,
} from '@flashpack/graphql';
import { usePaymentBreakdown, useRouting } from '@src/shared/hooks';
import {
  Box,
  CircleAddIcon,
  SharedAccommodationInclusionIcon,
  DiscountIcon,
  MobileModal,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from 'design-system';
import defaultImage from '@src/shared/book-departure-header/DepartureHeaderImage.png';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import { FC, useMemo, useState } from 'react';
import { OrderDetailsV3 } from '@src/v3/order-details-v3/OrderDetailsV3';

type PropTypes = {
  loadingOrderDetails: boolean;
  orderDetails?: OrderDetails;
  loadingBasket: boolean;
  basket?: Basket | null;
};

export const OrderDetailsMobile: FC<PropTypes> = (props) => {
  const { loadingOrderDetails, orderDetails, loadingBasket, basket } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();

  const breakdown = usePaymentBreakdown(basket);

  const { data, loading, error } = useQuery(TripCheckoutHeroBannerDocument, {
    variables: {
      tripCode: queryParams.departureCode?.split('_')[0],
    },
    skip: !queryParams.departureCode,
  });

  const hasAccommodationExtras = useMemo(
    () =>
      breakdown?.extrasPayItems.some((extra) =>
        extra.label.toLowerCase().includes('room'),
      ),
    [breakdown],
  );

  const hasOtherExtras = useMemo(
    () =>
      breakdown?.extrasPayItems.some(
        (extra) => !extra.label.toLowerCase().includes('room'),
      ),
    [breakdown],
  );

  if (loadingOrderDetails) {
    return <Skeleton variant="rectangular" height={48} width={48} />;
  }

  if (!orderDetails) {
    return null;
  }

  const { tripName } = orderDetails;
  return (
    <>
      {modalOpen && (
        <MobileModal
          open={modalOpen}
          closeButtonText="Close"
          onClose={() => setModalOpen(false)}
          headerSx={{ backgroundColor: theme.palette.principal.grey30 }}
        >
          <Stack
            px={3}
            pb={3}
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: theme.palette.principal.grey30 }}
          >
            <Box mb={5}>
              <OrderDetailsV3
                orderDetails={orderDetails}
                loadingOrderDetails={loadingOrderDetails}
                basket={basket}
                loadingBasket={loadingBasket}
                currencyCode={queryParams.currencyCode as CustomerCurrency}
                showTripImage={true}
              />
            </Box>
          </Stack>
        </MobileModal>
      )}
      <OrderDetailsMiniSection onClick={() => setModalOpen(true)}>
        <Stack direction="row" padding={2} gap={2}>
          <DepartureImage loading={loading} error={error} data={data} />
          <Stack alignItems="flex-start" gap={1} sx={{ flex: 1 }}>
            <Typography
              variant="Body M bold"
              noWrap
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '200px' }}
            >
              {tripName}
            </Typography>
            <Typography variant="Additional" sx={{ color: '#228092' }}>
              Show details
            </Typography>
          </Stack>
          {
            <Stack>
              <Typography variant="Body M bold">
                {formatCurrency(
                  basket?.tripTotal || orderDetails.tripPrice || 0,
                  (basket?.currency ||
                    queryParams.currencyCode?.toLowerCase()) as CustomerCurrency,
                )}
              </Typography>
              <Stack
                display="flex"
                direction="row"
                justifyContent="flex-end"
                mt={0.5}
                gap={0.5}
              >
                {hasAccommodationExtras && (
                  <SharedAccommodationInclusionIcon
                    sx={{ height: '18px', width: '18px' }}
                  />
                )}
                {hasOtherExtras && (
                  <CircleAddIcon sx={{ fill: 'none', height: '18px', width: '18px' }} />
                )}
                {breakdown?.hasDiscounts && (
                  <DiscountIcon sx={{ height: '18px', width: '18px' }} />
                )}
              </Stack>
            </Stack>
          }
        </Stack>
      </OrderDetailsMiniSection>
    </>
  );
};

const OrderDetailsMiniSection = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  left: 8,
  right: 8,
  borderRadius: '4px',
  boxShadow: `0px 6px 30px ${theme.spacing(1)} #20202040`,
  backgroundColor: theme.palette.principal.white,
}));

type DepartureImageProps = {
  width?: number | string;
  height?: number | string;
  loading: boolean;
  error: ApolloError | undefined;
  data?: TripCheckoutHeroBannerQuery;
};

const DepartureImage = ({
  width = 48,
  height = 48,
  loading,
  error,
  data,
}: DepartureImageProps) => {
  if (loading) {
    return <Skeleton variant="rectangular" height={height} width={width} />;
  }

  if (error || !data?.trip?.checkoutHeroBanner) {
    return (
      <DepartureImageStyled
        src={defaultImage}
        alt="Trip Image"
        width={width}
        height={height}
      />
    );
  }

  return (
    <DepartureImageStyled
      src={data.trip.checkoutHeroBanner}
      alt="Trip Image"
      width={width}
      height={height}
    />
  );
};

const DepartureImageStyled = styled('img')(({ width, height }) => ({
  width,
  height,
  objectFit: 'cover',
  objectPosition: 'center',
  position: 'relative',
  zIndex: 1,
  borderRadius: '8px',
}));
