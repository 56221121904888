import {
  Stack,
  ApplePayIcon,
  GooglePayIcon,
  PaymentOptionVisaIcon,
  PaymentOptionAmexIcon,
  PaymentOptionMastercardIcon,
} from 'design-system';

export const PaymentIcons = () => {
  return (
    <Stack direction="row" spacing={3}>
      <ApplePayIcon sx={{ height: 28, width: 45 }} />
      <GooglePayIcon sx={{ height: 28, width: 42 }} />
      <PaymentOptionVisaIcon sx={{ height: 28, width: 47 }} />
      <PaymentOptionMastercardIcon sx={{ height: 28, width: 29 }} />
      <PaymentOptionAmexIcon sx={{ height: 28, width: 27 }} />
    </Stack>
  );
};
