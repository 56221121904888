import { useMutation } from '@apollo/client';
import {
  Basket,
  CurrentMinimalCustomerDetailsQuery,
  UpdateCustomerBillingDetailsDocument,
} from '@flashpack/graphql';
import { BookingTermsAndConditionsFormField } from '@src/shared/booking-terms-and-conditions/BookingTermsAndConditionsFormField';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { useRouting } from '@src/shared/hooks';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { MoneyIsSafeQuote } from '@src/v3/DynamicExplainer/DynamicExplainer';
import {
  FormDropdown,
  Box,
  composeValidators,
  FormTextInput,
  Stack,
  Typography,
  Validator,
  ValidatorFactory,
  createPrioritizedCountryOptions,
  LoadingButton,
  useTheme,
  useMediaQuery,
} from 'design-system';
import { FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';

type PropTypes = {
  activeSection: CheckoutSectionName;
  completed: boolean;
  onComplete: (sectionName: CheckoutSectionName) => void;
  basket: Basket | null;
  editable: boolean;
  customerDetails?: CurrentMinimalCustomerDetailsQuery['currentCustomerDetails'] | null;
  customerDetailsLoading: boolean;
};

type FormValues = {
  acceptedTermsAndConditions: boolean;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
};

const prioritizedCountryOptions = createPrioritizedCountryOptions(['us', 'gb']).map(
  (option) => ({
    label: option.label,
    value: option.value.toUpperCase(),
  }),
);

export const BillingDetailsSection: FC<PropTypes> = (props) => {
  const {
    activeSection,
    completed,
    onComplete,
    basket,
    editable,
    customerDetails,
    customerDetailsLoading,
  } = props;

  const [updateBillingDetails] = useMutation(UpdateCustomerBillingDetailsDocument);
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const termsAndConditions = basket?.terms?.content;

  const onSubmit = async (values: FormValues) => {
    const nationality = values.country;

    await updateBillingDetails({
      variables: {
        input: {
          address: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            countryCode: values.country,
            postCode: values.postcode,
          },
          nationality,
        },
      },
    });

    onComplete(CheckoutSectionName.BillingDetailsSection);
  };

  const initialValues = useMemo(() => {
    return {
      addressLine1: customerDetails?.address?.addressLine1,
      addressLine2: customerDetails?.address?.addressLine2 ?? undefined,
      city: customerDetails?.address?.city,
      country: customerDetails?.address?.countryCode,
      postcode: customerDetails?.address?.postCode,
    };
  }, [customerDetails]);

  if (customerDetailsLoading) {
    return null;
  }

  return (
    <CheckoutExpandableSection
      title="Billing information"
      open={activeSection === CheckoutSectionName.BillingDetailsSection}
      completed={completed}
      editable={editable}
      onEdit={() => {
        updateQueryParams(
          {
            preserveExisting: true,
            toAdd: { section: CheckoutSectionName.BillingDetailsSection },
          },
          { replace: true },
        );
      }}
    >
      <Form<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting }) => (
          <Stack gap={2}>
            <Typography variant={isMobile ? 'Body M bold' : 'H5'} mt={isMobile ? 2 : 5}>
              Billing address
            </Typography>
            <FormTextInput
              name="addressLine1"
              validate={Validator.required}
              textInputProps={{
                placeholder: 'Enter address...',
                label: 'Address line 1',
                size: 'large',
                autoComplete: 'address-line1',
              }}
            />
            <FormTextInput
              name="addressLine2"
              textInputProps={{
                placeholder: 'Enter address...',
                label: 'Address line 2',
                size: 'large',
                autoComplete: 'address-line2',
              }}
            />
            <FormTextInput
              name="city"
              validate={Validator.required}
              textInputProps={{
                placeholder: 'Enter town/city...',
                label: 'Town/City',
                size: 'large',
                autoComplete: 'address-level2',
              }}
            />
            <FormTextInput
              name="postcode"
              textInputProps={{
                placeholder: 'Enter code...',
                label: 'Postcode/Zipcode',
                autoComplete: 'postal-code',
              }}
              validate={composeValidators(
                Validator.required,
                ValidatorFactory.createMaxLength(10),
              )}
            />
            <FormDropdown
              name="country"
              options={prioritizedCountryOptions}
              validate={Validator.required}
              TextInputProps={{
                size: 'large',
                label: 'Country',
                placeholder: 'Select country...',
                testid: 'select-country',
                inputProps: {
                  autoComplete: 'nope', // 'country',
                },
              }}
            />
            {isMobile && <MoneyIsSafeQuote />}
            {termsAndConditions && (
              <Box mt={1}>
                <Field
                  name="acceptedTermsAndConditions"
                  type="checkbox"
                  validate={Validator.required}
                >
                  {(props) => (
                    <BookingTermsAndConditionsFormField
                      termsAndConditionsHTML={termsAndConditions}
                      {...props}
                    />
                  )}
                </Field>
              </Box>
            )}
            <LoadingButton
              type="submit"
              loading={submitting}
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: theme.palette.principal.black,
              }}
              variant="contained"
              onClick={() => {
                void handleSubmit();
              }}
            >
              Save & continue
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </CheckoutExpandableSection>
  );
};

export const BillingDetailsSectionTitle = ({ completed }: { completed: boolean }) => {
  return (
    <CheckoutExpandableSection
      bottomDivider={false}
      topDivider={false}
      title="Billing information"
      completed={completed}
    />
  );
};
