import { Divider } from 'design-system';
import { TripBookedSection } from './TripBookedSection';
import DontBookFlightsYetIcon from './images/No.svg';

export const DontBookFlightsYet = () => {
  return (
    <>
      <TripBookedSection
        icon={<img src={DontBookFlightsYetIcon} alt="Don't book flights yet" />}
        title="Don’t book your flights just yet!"
        description="We will notify you when on the app, by email and by SMS"
      />
      <Divider sx={{ mt: 2 }} />
    </>
  );
};
