import { useMutation, useQuery } from '@apollo/client';
import {
  CurrentCustomerDetailsDocument,
  CurrentCustomerDetailsQuery,
  UpdateCustomerPersonalDetailsDocument,
} from '@flashpack/graphql';
import parsePhoneNumber from 'libphonenumber-js';

import {
  Box,
  Button,
  composeValidators,
  EditIcon,
  FormPhoneInput,
  FormTextInput,
  GenericError,
  Skeleton,
  Stack,
  Typography,
  Validator,
  LoadingButton,
} from 'design-system';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useFirebaseAuth } from '@src/auth/useAuthentication';

const headerText = 'Your details';

interface FormValues {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const MyDetailsSection = () => {
  const [editMode, setEditMode] = useState(false);
  const disabled = !editMode;

  const { data, loading, error } = useQuery(CurrentCustomerDetailsDocument);
  const [updateCustomerDetails] = useMutation(UpdateCustomerPersonalDetailsDocument);
  const { signInWithCustomToken } = useFirebaseAuth();

  const onSubmit = async (values: FormValues) => {
    const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
    if (!parsedPhoneNumber) {
      throw new Error('Invalid phone number');
    }

    const updatedCustomer = await updateCustomerDetails({
      variables: {
        input: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          phoneCountryCode: parsedPhoneNumber.countryCallingCode,
          phoneNumber: values.phoneNumber,
        },
      },
    });

    const customToken = updatedCustomer.data?.updateCustomerPersonalDetails.customToken;

    if (customToken) {
      // await silentSignOut();
      await signInWithCustomToken(customToken);
    }

    setEditMode(false);
  };

  if (loading) {
    return (
      <Box>
        <Typography variant="subHeader" mb={2}>
          {headerText}
        </Typography>
        <Skeleton variant="rectangular" height={500} />
      </Box>
    );
  }

  if (!data || error) {
    return <GenericError error="We can't load your details at the moment." />;
  }

  const initialValues = mapToInitialValues(data);

  return (
    <Box>
      <Form<FormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <Stack direction="column" gap={2}>
                <Typography variant="subHeader">{headerText}</Typography>
                <FormTextInput
                  name="firstName"
                  validate={Validator.required}
                  textInputProps={{
                    disabled,
                    placeholder: 'Enter first name...',
                    label: 'First Name (as on passport)',
                    testid: 'create-account-first-name',
                    size: 'fullWidth',
                  }}
                />
                <FormTextInput
                  name="middleName"
                  textInputProps={{
                    disabled,
                    placeholder: 'Enter middle name...',
                    label: 'Middle Name (as on passport)',
                    testid: 'create-account-middle-name',
                    size: 'fullWidth',
                  }}
                />
                <FormTextInput
                  name="lastName"
                  textInputProps={{
                    disabled,
                    placeholder: 'Enter last name...',
                    label: 'Last Name (as on passport)',
                    testid: 'create-account-last-name',
                    size: 'fullWidth',
                  }}
                  validate={Validator.required}
                />
                <FormTextInput
                  name="email"
                  textInputProps={{
                    disabled,
                    placeholder: 'Enter email address...',
                    label: 'Email Address',
                    testid: 'create-account-email',
                    size: 'fullWidth',
                  }}
                  validate={composeValidators(Validator.required, Validator.validEmail)}
                />
                <Field
                  name="phoneNumber"
                  validate={composeValidators(
                    Validator.required,
                    Validator.validPhoneNumber,
                  )}
                >
                  {(props) => (
                    <FormPhoneInput
                      readOnly={disabled}
                      defaultCallingCode="+44"
                      {...props}
                      data-testid="create-account-phone-number"
                      label="Phone Number"
                      callingCodeInputProps={{
                        disabled,
                      }}
                      numberInputProps={{
                        disabled,
                        name: 'phoneNumber',
                        placeholder: 'Enter phone number...',
                        size: 'fullWidth',
                      }}
                    />
                  )}
                </Field>
                {!editMode && (
                  <Button
                    sx={{ ':not(:hover)': { '& .MuiSvgIcon-root': { fill: 'none' } } }}
                    onClick={() => setEditMode(true)}
                    variant="outlined"
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                )}
                {editMode && (
                  <Stack direction="row" gap={2}>
                    <Button
                      fullWidth
                      onClick={() => {
                        form.restart();
                        setEditMode(false);
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={submitting}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </form>
          );
        }}
      />
    </Box>
  );
};

const mapToInitialValues = (data: CurrentCustomerDetailsQuery) => {
  return {
    firstName: data.currentCustomerDetails.firstName,
    middleName: data.currentCustomerDetails.middleName ?? undefined,
    lastName: data.currentCustomerDetails.lastName,
    email: data.currentCustomerDetails.email,
    phoneNumber: data.currentCustomerDetails.phoneNumber ?? '',
  };
};
