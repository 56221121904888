import React from 'react';
import { Basket, CustomerCurrency, PaymentOptionType } from '@flashpack/graphql';
import {
  ChevronRightIcon,
  Box,
  CalendarCheckedIcon,
  CalendarIcon,
  RadioAccordionGroupOption,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from 'design-system';
import { formatCurrency } from '../../shared/utils/currencyUtils';
import {
  getPaymentPlanLatestStartDate,
  InstalmentsCalculator,
} from '@src/shared/instalments-calculator/InstalmentsCalculator';

interface PaymentPlanOptionProps {
  basket: Basket;
  departureDate: string;
  paymentPlanType: PaymentOptionType.ThreeMonthsPlan | PaymentOptionType.SixMonthsPlan;
  paymentPlanStartDate: string | null;
}

export const PaymentPlanOptionContentV2: React.FC<PaymentPlanOptionProps> = (props) => {
  const { basket, paymentPlanStartDate, paymentPlanType } = props;

  const currency = basket.currency as CustomerCurrency;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const latestPaymentPlanStartDate = getPaymentPlanLatestStartDate({
    departureDate: props.departureDate,
    paymentOption: props.paymentPlanType,
  });

  const numberOfInstalments =
    paymentPlanType === PaymentOptionType.ThreeMonthsPlan ? 3 : 6;

  const instalmentCalculator = new InstalmentsCalculator({
    totalCost: basket.tripTotal,
    currency,
    paymentOption: props.paymentPlanType,
    departureDate: props.departureDate,
    depositAmount: basket.tourDeposit,
    paymentPlanStartDate: paymentPlanStartDate ?? latestPaymentPlanStartDate,
  });
  const { instalments, error } = instalmentCalculator.calculate();

  const instalmentsWithDeposit = [
    {
      date: paymentPlanStartDate,
      amount: basket.tourDeposit,
      dateYyyyMmDd: paymentPlanStartDate,
    },
    ...instalments,
  ];

  return (
    <Stack gap={2}>
      <Typography variant="Body M">
        Pay a <b>{formatCurrency(basket.tourDeposit, currency)}</b> deposit today and
        spread the remaining payments over these {numberOfInstalments} months.
      </Typography>
      <Box
        sx={{
          overflowY: 'hidden',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          maxWidth: `${window.innerWidth - 102}px`,
        }}
      >
        <Stack direction="row" sx={{ display: 'inline-flex' }} mb={isMobile ? 1 : 0}>
          {instalmentsWithDeposit.map((instalment, instalmentIdx) => (
            <>
              <Stack
                key={instalment.date || instalmentIdx}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{ minWidth: '60px' }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      instalmentIdx === 0
                        ? theme.palette.principal.black
                        : theme.palette.principal.grey30,
                    borderRadius: '8px',
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {instalmentIdx === 0 ? <CalendarCheckedIcon /> : <CalendarIcon />}
                </Box>
                <Typography variant={instalmentIdx === 0 ? 'Body M bold' : 'Body M'}>
                  {formatCurrency(instalment.amount, currency)}
                </Typography>
                <Typography
                  variant="Additional"
                  sx={{ color: theme.palette.principal.grey70 }}
                >
                  {instalmentIdx === 0 ? 'Today ' : instalment.date}
                </Typography>
              </Stack>
              {instalmentIdx !== instalmentsWithDeposit.length - 1 && (
                <Box mt={1}>
                  <ChevronRightIcon />
                </Box>
              )}
            </>
          ))}
        </Stack>
      </Box>

      {error && (
        <Typography variant="Body S" color="error" sx={{ pt: 2 }}>
          {error}
        </Typography>
      )}
    </Stack>
  );
};

export const createPaymentPlanOptionContentV2: (
  params: PaymentPlanOptionProps,
) => RadioAccordionGroupOption = (params) => {
  return {
    value: params.paymentPlanType,
    label: `${
      params.paymentPlanType === PaymentOptionType.ThreeMonthsPlan ? '3' : '6'
    } month payment plan`,
    endAdornment: (
      <Typography variant="Body M">
        {formatCurrency(
          params.basket.tourDeposit,
          params.basket.currency as CustomerCurrency,
        )}{' '}
        today
      </Typography>
    ),
    content: <PaymentPlanOptionContentV2 {...params} />,
  };
};
