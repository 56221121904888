import { Box, Stack, useMediaQuery, useTheme } from 'design-system';
import QRCode from 'react-qr-code';
import { TripBookedSection } from './TripBookedSection';
import DownloadFlashPackAppIcon from './images/Download.svg';
import AppScreenshotsImage from './images/app-screenshots.png';
import AppStoreBadgeIcon from './images/app-store-badge.svg';
import GooglePlayBadgeIcon from './images/google-play-badge.svg';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';

const appStoreLink = import.meta.env.VITE_APP_STORE_LINK_IOS;
const playStoreLink = import.meta.env.VITE_APP_STORE_LINK_ANDROID;

export const DownloadFlashPackApp = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <TripBookedSection
        icon={<img src={DownloadFlashPackAppIcon} alt="Download Flash Pack App" />}
        title="Download the Flash Pack app"
        description="Find all the information needed to prepare for your trip and keep notified of all the latest developments"
        content={
          <Stack
            gap={3}
            direction="row"
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems="center"
            mt={2}
          >
            {!isMobile && (
              <Box flexBasis="50%" display="flex" justifyContent="center">
                <img
                  width="244px"
                  height="328px"
                  src={AppScreenshotsImage}
                  alt="App Screenshots"
                />
              </Box>
            )}

            <Stack gap={3} flexBasis="50%" justifyContent="center" alignItems="center">
              <Box display="flex">
                <a href={appStoreLink} target="_blank" rel="noreferrer">
                  <img src={AppStoreBadgeIcon} alt="App Store" />
                </a>
              </Box>

              <Box display="flex">
                <a href={playStoreLink} target="_blank" rel="noreferrer">
                  <img src={GooglePlayBadgeIcon} alt="Google Play" />
                </a>
              </Box>

              {!isMobile && (
                <Box
                  borderRadius="8px"
                  border="8px solid white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <QRCode
                    size={120}
                    value={
                      window.location.origin +
                      CheckoutRoutePath.DOWNLOAD_FLASHPACK_APP.value
                    }
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
