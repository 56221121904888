import { ApolloError, useMutation, useQuery } from '@apollo/client';
import {
  Basket,
  CreateBasketDocument,
  CurrentBasketDocument,
  CustomerCurrency,
} from '@flashpack/graphql';
import { useEffect, useState } from 'react';
import { useSoldOutRedirect } from './useSoldOutRedirect';
import { hasAnyErrorWithCode } from '@src/shared/utils/errorUtils';
import { useRouting } from '@src/shared/hooks/useRouting';

export const useBasket = ({
  skip = false,
}: {
  skip?: boolean;
} = {}) => {
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();

  const { departureCode, currencyCode } = queryParams;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;

  const [error, setError] = useState<ApolloError | null>(null);
  const [loading, setLoading] = useState(true);
  const [basket, setBasket] = useState<Basket | null>(null);
  const { runRedirect: redirectToSoldOutPage } = useSoldOutRedirect();

  const {
    refetch,
    data,
    loading: loadingBasket,
    error: basketError,
  } = useQuery(CurrentBasketDocument, {
    variables: {
      input: {
        departureCode,
        currency: customerCurrency,
      },
    },
    skip,
  });

  const [createBasket] = useMutation(CreateBasketDocument);

  useEffect(() => {
    if (skip) {
      setLoading(false);
      return;
    }

    const init = async () => {
      if (loadingBasket) {
        return;
      }

      if (basketError) {
        setError(basketError);
        setLoading(false);
      } else if (data?.currentBasket) {
        setBasket(data.currentBasket);
        setLoading(false);
      } else {
        try {
          await createBasket({
            variables: {
              input: {
                departureCode,
                currency: customerCurrency,
              },
            },
          });

          await refetch();
        } catch (e) {
          if (hasAnyErrorWithCode(e, 'TRIP_SOLD_OUT')) {
            redirectToSoldOutPage();
          } else {
            setError(e as ApolloError);
          }

          setLoading(false);
        }
      }
    };
    void init();
  }, [
    data,
    basketError,
    createBasket,
    refetch,
    departureCode,
    customerCurrency,
    loadingBasket,
    redirectToSoldOutPage,
    skip,
  ]);

  return {
    loading,
    error,
    basket,
  };
};
