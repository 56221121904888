import { Field } from 'react-final-form';
import { AutocompleteValue } from '@mui/material/Autocomplete';
import Dropdown, { DropdownProps } from './Dropdown';

interface FormDropdownProps<
  T extends { value: string },
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
    DropdownProps<T, Multiple, DisableClearable, FreeSolo>,
    'onChange' | 'value'
  > {
  validateFields?: string[];
  parse?: (value: string) => string;
  format?: (value: string) => string;
  validate?: (value: string) => string | undefined;
  getOptionValue?: (option: T) => string;
}

const defaultGetOptionValue = <T extends { value: string }>(option: T) => option.value;

export const FormDropdown = <
  T extends { value: string; label: string },
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: FormDropdownProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const {
    validate,
    validateFields,
    getOptionValue = defaultGetOptionValue,
    ...rest
  } = props;

  return (
    <Field<string>
      name={props.name}
      type="select"
      parse={props.parse}
      format={props.format}
      validate={validate}
      validateFields={validateFields}
    >
      {({ input, meta }) => {
        const { onChange, ...forwardInputProps } = input;

        const handleChange = (
          _event: React.SyntheticEvent,
          selectedOption: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
        ) => {
          if (selectedOption !== null) {
            if (Array.isArray(selectedOption)) {
              onChange(
                selectedOption
                  .map((option) =>
                    typeof option === 'string' ? option : getOptionValue(option),
                  )
                  .join(','),
              );
            } else if (typeof selectedOption === 'string') {
              onChange(selectedOption);
            } else {
              const value = getOptionValue(selectedOption as T);
              onChange(value);
            }
          } else {
            onChange(null);
          }
        };

        const value =
          props.options.find((option) => getOptionValue(option) === input.value) || null;

        return (
          <Dropdown
            {...rest}
            active={meta.active}
            onChange={handleChange}
            value={value as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>}
            TextInputProps={{
              ...forwardInputProps,
              ...props.TextInputProps,
              type: 'select',
              helperText: props.TextInputProps?.helperText || (meta.error as string),
              error: meta.touched && !!meta.error,
            }}
          />
        );
      }}
    </Field>
  );
};
