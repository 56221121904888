import {
  Button,
  CompleteIcon,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from 'design-system';
import { FC } from 'react';

interface PropTypes {
  open?: boolean;
  title?: string;
  children?: React.ReactNode;
  topDivider?: boolean;
  bottomDivider?: boolean;
  completed?: boolean;
  editable?: boolean;
  onEdit?: () => void;
}

export const CheckoutExpandableSection: FC<PropTypes> = ({
  open = false,
  title,
  children,
  completed = false,
  topDivider = true,
  bottomDivider = false,
  onEdit,
  editable = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack direction="column" gap={0}>
        {topDivider && <Divider sx={{ mt: 0, mb: 4 }} />}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {title && <Typography variant={isMobile ? 'H5' : 'H4'}>{title}</Typography>}
          <Stack direction="row" gap={2} alignItems="center">
            {!open && completed && editable && (
              <Button variant="outlined" onClick={() => onEdit?.()}>
                Edit
              </Button>
            )}
            {completed && <CompleteIcon sx={{ width: 32, height: 32 }} />}
          </Stack>
        </Stack>
        {open && children}
        {bottomDivider && <Divider sx={{ mt: 4, mb: 0 }} />}
      </Stack>
    </>
  );
};
