import { useEffect, useState } from 'react';

import { useRouting } from './useRouting';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { ProductLineSlug } from '@flashpack/graphql';
import { differenceInYears } from 'date-fns';

/**
 * Current Business logic:
 *
 * <30 cannot book register / book trips
 * 30-45 can book regular, not 45-59 trips
 * 45-50 can book both
 * 51-59 can only book 45-59 trips
 * 60+ cannot register / book trips
 */

export const useOutOfAgeRangeRedirect = ({
  skip,
  productLineSlug,
  userDateOfBirth,
}: {
  skip?: boolean;
  productLineSlug?: string | null;
  userDateOfBirth?: string;
}) => {
  const { navigate } = useRouting();
  const [isInAgeSweetspot, setIsInAgeSweetspot] = useState(false);
  // redirect to appropriate page if product line is not available for the age range
  useEffect(() => {
    if (skip || !productLineSlug || !userDateOfBirth) {
      return;
    }
    const userAge = differenceInYears(new Date(), new Date(userDateOfBirth));
    if (productLineSlug === ProductLineSlug.Age_45_59) {
      if (userAge < 45) {
        navigate(CheckoutRoutePath.TOO_YOUNG.value, { preserveExisting: true });
      } else if (userAge > 59) {
        navigate(CheckoutRoutePath.OUT_OF_AGE_RANGE.value, { preserveExisting: true });
      } else if (userAge >= 45 && userAge <= 50) {
        setIsInAgeSweetspot(true);
      }
    } else {
      // regular product line
      if (userAge > 50) {
        navigate(CheckoutRoutePath.TOO_OLD.value, { preserveExisting: true });
      }
    }

    return () => {};
  }, [navigate, productLineSlug, userDateOfBirth, skip]);

  return { isInAgeSweetspot };
};
