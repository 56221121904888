import { useMutation } from '@apollo/client';
import {
  Basket,
  CreatePaymentIntentDocument,
  CustomerCurrency,
} from '@flashpack/graphql';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '@src/airwallex/AirwallexDropIn';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import { FC, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { HavingProblemsPayingQuote } from '@src/v3/DynamicExplainer/DynamicExplainer';

type PropTypes = {
  activeSection: CheckoutSectionName;
  basket: Basket;
  customerCurrency: CustomerCurrency;
  completed: boolean;
  onComplete: (sectionName: CheckoutSectionName) => void;
  editable?: boolean;
};

export const PaySection: FC<PropTypes> = (props) => {
  const { activeSection, basket, customerCurrency, completed, onComplete, editable } =
    props;
  const [createPaymentIntent] = useMutation(CreatePaymentIntentDocument);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleAirwallexSuccess = useCallback(() => {
    onComplete(CheckoutSectionName.PaySection);
  }, [onComplete]);

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureException('Airwallex payment failed', {
      level: 'warning',
      extra: { error: JSON.stringify(error) },
    });
  }, []);

  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    const { data: paymentIntent } = await createPaymentIntent({
      variables: {
        input: {
          amount: basket.tripTotal, // todo: remove it; take the value in the server
          currency: customerCurrency,
          basketId: basket.id,
        },
      },
    });
    if (paymentIntent) {
      return paymentIntent.createPaymentIntent as CreatedPaymentIntent;
    }
    throw new Error('Failed to create intent!');
  };

  return (
    <CheckoutExpandableSection
      title="Pay"
      open={activeSection === CheckoutSectionName.PaySection}
      completed={completed}
      editable={editable}
    >
      {editable && (
        <Box mt={3}>
          <AirwallexDropInElement
            onSuccess={handleAirwallexSuccess}
            onError={handleAirwallexError}
            createIntent={createIntent}
            dropInType="payment"
          />
        </Box>
      )}
      {isMobile && <HavingProblemsPayingQuote />}
    </CheckoutExpandableSection>
  );
};

export const PaySectionTitle = () => {
  return <CheckoutExpandableSection title="Pay" />;
};
