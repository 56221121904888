import React from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { TextInput, TextInputProps } from './TextInput';

interface FormTextInputProps {
  name: string;
  parse?: (value: string) => string;
  format?: (value: string) => string;
  validateFields?: string[];
  validate?: FieldValidator<string>;
  textInputProps: Omit<TextInputProps, 'name' | 'value' | 'onChange'>;
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  parse,
  format,
  validate,
  validateFields,
  textInputProps,
}) => {
  return (
    <Field<string, HTMLElement>
      name={name}
      parse={parse}
      format={format}
      validate={validate}
      validateFields={validateFields}
      render={({ input, meta }) => {
        return (
          <TextInput
            {...input}
            {...textInputProps}
            name={name}
            error={meta.touched && !!meta.error}
            helperText={textInputProps.helperText || (meta.error as string)}
          />
        );
      }}
    />
  );
};
