import React, { useCallback, useState } from 'react';
import { Typography, Stack, LoadingButton, Skeleton, GenericError } from 'design-system';
import { AirwallexDropInElement, CreatedIntent } from '@src/airwallex/AirwallexDropIn';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ClientPaymentSecretDocument,
  CurrentCustomerDetailsDocument,
} from '@flashpack/graphql';
import * as Sentry from '@sentry/react';
import { useToast } from '@src/shared/toast/useToast';

export const UpdateCardDetailsDropIn: React.FC = () => {
  const [showUpdateCardWidget, setShowUpdateCardWidget] = useState(false);
  const [updatingCardDetails, setUpdatingCardDetails] = useState(false);
  const { success: successToast, error: errorToast } = useToast();

  const {
    data,
    refetch: refetchCustomerDetails,
    loading: loadingCustomerDetails,
    error: errorCustomerDetails,
  } = useQuery(CurrentCustomerDetailsDocument);

  const savedCardLastDigits = data?.currentCustomerDetails?.savedCardLastDigits;

  const [getClientSecret, { loading, error }] = useLazyQuery(ClientPaymentSecretDocument);

  const handleFetchClientSecret = async () => {
    const clientSecret = await getClientSecret();
    return clientSecret.data?.clientPaymentSecret as CreatedIntent;
  };

  const handleSuccess = async () => {
    setUpdatingCardDetails(true);

    try {
      // we need to wait for the airwallex webhook to call the booking engine to update the card details
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await refetchCustomerDetails();
      successToast('Card details updated successfully');
    } finally {
      setShowUpdateCardWidget(false);
      setUpdatingCardDetails(false);
    }
  };

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureException('Airwallex update payment method failed', {
      level: 'error',
      extra: { error: JSON.stringify(error) },
    });
    errorToast('Error updating card details. Please try again later.');
  }, []);

  if (loadingCustomerDetails) {
    return <Skeleton variant="rectangular" height={500} />;
  }

  if (errorCustomerDetails) {
    return <GenericError error="We can't load your card details at the moment." />;
  }

  return (
    <Stack marginTop={2}>
      {updatingCardDetails && <Skeleton variant="rectangular" height={100} />}
      {!updatingCardDetails && savedCardLastDigits && (
        <Typography variant="Body S" display="flex" sx={{ mb: 2 }}>
          Your saved card ends in
          <Typography variant="Body S bold" sx={{ ml: '4px' }}>
            {savedCardLastDigits}
          </Typography>
        </Typography>
      )}
      {!showUpdateCardWidget && (
        <LoadingButton
          variant="outlined"
          sx={{ width: '200px' }}
          size="small"
          onClick={() => setShowUpdateCardWidget(true)}
          disabled={loading}
          loading={loading}
        >
          Update
        </LoadingButton>
      )}
      {error && (
        <Typography variant="Body M" color="error">
          Error updating card details. Please try again.
        </Typography>
      )}
      {showUpdateCardWidget && (
        <AirwallexDropInElement
          createIntent={handleFetchClientSecret}
          onSuccess={() => void handleSuccess()}
          onError={handleAirwallexError}
          dropInType="update"
        />
      )}
    </Stack>
  );
};
