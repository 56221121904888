import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FormatQuoteIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 23 17">
      <svg
        width="23"
        height="17"
        viewBox="0 0 23 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="&#226;&#128;&#156;"
          d="M17.8161 17C14.7176 17 12.215 14.3286 12.215 10.3214C12.215 6.07143 15.1943 1.51786 20.0803 0H22.1658C18.4715 2.00357 17.399 5.28214 17.399 6.98214C17.5181 7.16429 17.399 6.92143 17.399 6.92143C20.7358 6.73929 23 9.04643 23 12.0214C22.9404 14.8143 20.6166 17 17.8161 17ZM5.60104 17C2.50259 17 0 14.3286 0 10.3214C0 6.07143 2.97927 1.51786 7.86529 0H9.95078C6.25648 2.00357 5.18394 5.28214 5.18394 6.98214C5.30311 7.16429 5.18394 6.92143 5.18394 6.92143C8.52073 6.73929 10.785 9.04643 10.785 12.0214C10.7254 14.8143 8.40156 17 5.60104 17Z"
          fill="#202020"
        />
      </svg>
    </SvgIcon>
  );
};

export default FormatQuoteIcon;
