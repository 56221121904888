import { FC } from 'react';
import { Basket, CustomerCurrency } from '@flashpack/graphql';
import { ToPayLineItem } from '@src/pay/ToPayWidget';
import { usePaymentBreakdown } from '@src/shared/hooks';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import {
  useTheme,
  Stack,
  Box,
  Typography,
  SharedAccommodationInclusionIcon,
  CircleAddIcon,
  DiscountIcon,
} from 'design-system';

export const PaymentBreakdownV3: FC<{ basket: Basket }> = ({ basket }) => {
  const { currency: basketCurrency } = basket;
  const theme = useTheme();
  const currency = basketCurrency.toLowerCase() as CustomerCurrency;
  const breakdown = usePaymentBreakdown(basket);

  if (!breakdown) {
    return null;
  }

  const {
    tripPrice,
    extrasPayItems,
    earlyBirdDiscount,
    insiderDiscount,
    promoDiscount,
    promotionalDiscount,
    tripTotal,
  } = breakdown;

  const isAccommodationExtra = (extra: ToPayLineItem) =>
    extra.label.toLowerCase().includes('room');

  const filteredDiscountLineItems = [
    earlyBirdDiscount,
    insiderDiscount,
    promoDiscount,
    promotionalDiscount,
  ].filter((value) => value && value.price < 0) as ToPayLineItem[];

  const discountsPresent = filteredDiscountLineItems.length > 0;

  return (
    <Stack gap={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="Body M">Trip Price</Typography>
        <Typography variant="Body M bold">
          {formatCurrency(tripPrice.price, currency)}
        </Typography>
      </Box>
      <Stack gap={1}>
        {extrasPayItems.map((item) => (
          <Box display="flex" justifyContent="space-between" key={item.label}>
            <Box display="flex" alignItems="flex-start" gap={1}>
              {isAccommodationExtra(item) ? (
                <SharedAccommodationInclusionIcon
                  sx={{ height: '20px', width: '20px', marginTop: '4px' }}
                />
              ) : (
                <CircleAddIcon
                  sx={{ fill: 'none', height: '20px', width: '20px', marginTop: '4px' }}
                />
              )}
              <Typography variant="Body M" sx={{ marginRight: '10px' }}>
                {item.label}
              </Typography>
            </Box>
            <Typography variant="Body M bold">
              {formatCurrency(item.price, currency)}
            </Typography>
          </Box>
        ))}

        {discountsPresent && (
          <Stack
            gap={1}
            sx={{
              borderTop: `1px dotted ${theme.palette.principal.grey50}`,
              borderBottom: `1px dotted ${theme.palette.principal.grey50}`,
              py: 1,
            }}
          >
            {filteredDiscountLineItems.map((item) => (
              <Box display="flex" justifyContent="space-between" key={item.label}>
                <Box display="flex" alignItems="center" gap={1}>
                  <DiscountIcon sx={{ height: '20px', width: '20px' }} />
                  <Typography variant="Body M bold" color={'#3B844B'}>
                    {item.label.toUpperCase()}
                  </Typography>
                </Box>

                <Typography variant="Body M bold" color={'#3B844B'}>
                  {formatCurrency(item.price, currency)}
                </Typography>
              </Box>
            ))}
          </Stack>
        )}
      </Stack>

      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="H5">Total</Typography>
        <Typography variant="H5">{formatCurrency(tripTotal.price, currency)}</Typography>
      </Box>
    </Stack>
  );
};
