import React, { useState, useCallback } from 'react';
import {
  Box,
  ClickAwayListener,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { TripDepartureStatus } from '@flashpack/graphql';
import { InformationIcon } from 'design-system';

interface TripStatusProps {
  status: TripDepartureStatus;
  // whether to show the "Trip status" explanation above
  // or to just render the status pill
  showExplanationLabel?: boolean;
}

const StatusPill = styled(Box)<{ statuscolor: string }>(({ theme, statuscolor }) => ({
  height: 24,
  backgroundColor: statuscolor,
  borderRadius: 25,
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  width: 'fit-content',
  justifyContent: 'space-between',
  color: theme.palette.getContrastText(statuscolor),
}));

const getStatusColor = (status: TripDepartureStatus): string => {
  switch (status) {
    case TripDepartureStatus.FinalCall:
      return '#FDE5C5';
    case TripDepartureStatus.OnRequest:
      return '#F6F6F6';
    case TripDepartureStatus.Guaranteed:
      return '#E3FAE8';
    case TripDepartureStatus.SoldOut:
      return '#FFE8EA';
    default:
      return '#F6F6F6';
  }
};

const statusToTooltipText: Partial<Record<TripDepartureStatus, string>> = {
  [TripDepartureStatus.OnRequest]: `This trip is departing very soon. Book now to secure a spot but wait for our confirmation before booking flights. \n\nWe'll take payment right away but return it if we can't open up a space. (This normally takes 3-5 working days)`,
  [TripDepartureStatus.Guaranteed]: `You can book your flights. For other statuses, we'll notify you when to book.`,
  [TripDepartureStatus.OnSale]: `This departure is not yet guaranteed.`,
  [TripDepartureStatus.FinalCall]: `This trip is almost full or will become On Request less than 2 weeks from now.`,
  [TripDepartureStatus.Completed]: `This trip has departed.`,
};

const knownStatusesSet = new Set(Object.values(TripDepartureStatus));

const statusToLabel: Record<TripDepartureStatus, string> = {
  [TripDepartureStatus.Guaranteed]: 'Guaranteed',
  [TripDepartureStatus.SoldOut]: 'Sold out',
  [TripDepartureStatus.OnRequest]: 'On Request',
  [TripDepartureStatus.OnSale]: 'Available',
  [TripDepartureStatus.FinalCall]: 'Final call',
  [TripDepartureStatus.Completed]: 'Completed',
};

/**
 * TripStatus component
 *
 * @description This component was copied from the WordPress repo. Any updates should be made there also.
 */
export const TripStatus: React.FC<TripStatusProps> = ({
  status,
  showExplanationLabel = true,
}) => {
  const theme = useTheme();
  const isCompactView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = useCallback(() => {
    setIsTooltipOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsTooltipOpen(true);
  }, []);

  if (!knownStatusesSet.has(status)) {
    return null;
  }
  const statusColor = getStatusColor(status as TripDepartureStatus);

  const tooltipContent = (
    <Typography sx={{ userSelect: 'none' }} variant="Body S" whiteSpace={'break-spaces'}>
      {statusToTooltipText[status]}
    </Typography>
  );

  return (
    <Box>
      {showExplanationLabel && (
        <Typography
          variant="Additional"
          color={isCompactView ? 'MenuText' : 'textSecondary'}
          gutterBottom
        >
          Trip status
        </Typography>
      )}
      <ClickAwayListener onClickAway={isTooltipOpen ? handleClose : () => {}}>
        <Tooltip
          disableFocusListener={isCompactView}
          disableHoverListener={isCompactView}
          disableTouchListener={isCompactView}
          title={tooltipContent}
          arrow
          open={isTooltipOpen}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <StatusPill
            statuscolor={statusColor}
            onClick={isCompactView ? handleTooltipToggle : () => {}}
            sx={{
              cursor: isCompactView ? 'pointer' : 'default',
              border:
                status === TripDepartureStatus.OnSale
                  ? `1px solid ${theme.palette.principal.grey50}`
                  : 'none',
            }}
          >
            <Typography variant="Body S">{statusToLabel[status] || ''}</Typography>

            <InformationIcon
              sx={{ fill: theme.palette.principal.black, width: 18, height: 18 }}
            />
          </StatusPill>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default TripStatus;
