import React, { useMemo } from 'react';
import { FC } from 'react';
import {
  useTheme,
  Typography,
  GenericError,
  Box,
  Stack,
  Skeleton,
  Divider,
} from 'design-system';

import {
  Basket,
  CustomerCurrency,
  OrderDetails as OrderDetailsType,
  TripDepartureStatus,
} from '@flashpack/graphql';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import TripStatus from './TripStatus';
import { OrderCardImage } from './OrderCardImage';
import { PaymentBreakdownV3 } from './PaymentBreakdownV3';
import { TripDisclaimer } from './TripDisclaimer';
import { SxProps } from '@mui/material';

type OrderDetailsPropType = Pick<
  OrderDetailsType,
  | 'tripName'
  | 'location'
  | 'startDate'
  | 'endDate'
  | 'duration'
  | 'description'
  | 'tripStatus'
  | 'tripPrice'
  | 'promoCodesEnabled'
  | 'tripNote'
  | 'tourNote'
>;
export type OrderDetailsPropTypes = {
  orderDetails?: OrderDetailsPropType;
  loadingOrderDetails: boolean;
  currencyCode: CustomerCurrency;
  basket?: Basket | null;
  loadingBasket: boolean;
  showTripImage?: boolean;
  sx?: SxProps;
};

export const OrderDetailsV3: React.FC<OrderDetailsPropTypes> = (props) => {
  const {
    orderDetails,
    loadingOrderDetails,
    showTripImage = true,
    basket,
    currencyCode,
    sx,
  } = props;
  const theme = useTheme();
  const dateLabels = useMemo(() => {
    if (!orderDetails || !orderDetails.startDate || !orderDetails.endDate) {
      return { startDate: '', endDate: '' };
    }
    const { startDate, endDate } = orderDetails;
    const start = formatDateToOrdinalMonthYear(startDate);
    const end = formatDateToOrdinalMonthYear(endDate);
    return {
      startDate: start,
      endDate: end,
    };
  }, [orderDetails]);

  if (loadingOrderDetails) {
    return <Skeleton variant="rectangular" height={547} sx={{ borderRadius: 6 }} />;
  }

  if (!orderDetails) {
    return (
      <GenericError
        error={
          'Something went wrong while fetching your order details. Please try again later.'
        }
      />
    );
  }

  const { tripName, duration, tripNote } = orderDetails;

  return (
    <Box
      sx={{ ...sx, backgroundColor: theme.palette.principal.grey30, borderRadius: '8px' }}
    >
      {showTripImage && <OrderCardImage />}
      <Stack gap={1} mb={2}>
        <Typography variant="H4">{tripName}</Typography>
        <Typography variant="Body M bold">
          {dateLabels.startDate} – {dateLabels.endDate}
        </Typography>
        <Typography variant="Body M">{duration}</Typography>
      </Stack>
      <TripDisclaimer tripNote={tripNote} />
      <TripStatus status={orderDetails.tripStatus as TripDepartureStatus} />

      <Divider />
      <TripPriceList
        orderDetails={orderDetails}
        basket={basket}
        currencyCode={currencyCode}
      />
    </Box>
  );
};

const TripPriceList: FC<{
  orderDetails: OrderDetailsPropType;
  basket?: Basket | null;
  currencyCode: CustomerCurrency;
}> = ({ orderDetails, basket, currencyCode }) => {
  if (!orderDetails) {
    return null;
  }

  if (!basket) {
    return (
      <Stack>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="Body M">Trip Price</Typography>
          <Typography variant="Body M bold">
            {formatCurrency(orderDetails?.tripPrice, currencyCode)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Typography variant="H5">Total</Typography>
          <Typography variant="H5">
            {formatCurrency(orderDetails?.tripPrice, currencyCode)}
          </Typography>
        </Box>
      </Stack>
    );
  }
  return <PaymentBreakdownV3 basket={basket} />;
};
