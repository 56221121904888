import { Box, Typography, useTheme } from 'design-system';
import defaultImage from '@src/shared/book-departure-header/DepartureHeaderImage.png';
import { pageHorizontalPadding, pageMaxWidthPx } from '@src/shared/Layout/Layout';

interface PropTypes {
  text?: string | React.ReactNode;
  loading?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  image?: string;
  imageAltText?: string;
}

/**
 * A header we render on top of each page.
 * Contains a trip-related background image and a text.
 */
export const HeroHeader: React.FC<PropTypes> = (props: PropTypes) => {
  const {
    loading,
    text,
    textAlign = 'center',
    image = defaultImage,
    imageAltText = 'Scenic Departure Header',
  } = props;

  const theme = useTheme();

  return (
    <Box position={'relative'}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: {
            xs: '152px',
            sm: '234px',
            md: '234px',
            lg: '316px',
          },
          overflow: 'hidden',
        }}
      >
        {!loading && (
          <img
            src={image}
            alt={imageAltText}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              position: 'absolute',
              zIndex: 1,
            }}
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.20) 41%, rgba(0, 0, 0, 0.45) 68.5%, rgba(0, 0, 0, 0.60) 100%)',
            zIndex: 2,
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingX: pageHorizontalPadding,
            paddingTop: '40px',
            paddingBottom: '40px',
            maxWidth: pageMaxWidthPx,
            height: '100%',
            display: 'flex',
            justifyContent: textAlign === 'left' ? 'start' : 'center',
            alignItems: 'end',
          }}
        >
          <Typography
            variant="H2"
            typography={{
              xs: 'H4',
              sm: 'H2',
              lg: 'H2',
            }}
            sx={{
              zIndex: 3,
              textAlign,
              maxWidth: '100%',
            }}
            color={theme.palette.common.white}
          >
            {loading ? null : text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
