import { useEffect } from 'react';

const isIOS = () => {
  // Check 1: Platform-specific check
  if (navigator.platform) {
    const platforms = ['iPhone', 'iPad', 'iPod'];
    if (platforms.includes(navigator.platform)) {
      return true;
    }
  }

  // Check 2: User Agent check
  const ua = navigator.userAgent;
  if (/iPad|iPhone|iPod/.test(ua)) {
    return true;
  }

  // Check 3: Vendor check (Safari-specific)
  if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1) {
    return true;
  }

  // Check 4: MaxTouchPoints check (Modern iOS devices)
  if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    return /Mac/.test(navigator.platform);
  }

  return false;
};

const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};

const playStoreLink = 'https://play.google.com/store/apps/details?id=com.flashpack.app';
const appStoreLink = 'https://apps.apple.com/us/app/flash-pack/id6461519436';

export const DynamicDownloadFlashpackAppPage = () => {
  useEffect(() => {
    if (isIOS()) {
      window.location.replace(appStoreLink);
    } else if (isAndroid()) {
      window.location.replace(playStoreLink);
    }
  }, []);

  return null;
};
