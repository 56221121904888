import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Field, Form, FormSpy } from 'react-final-form';
import {
  UpdateBasketDocument,
  CustomerCurrency,
  PaymentOptionType,
  CreatePaymentIntentDocument,
  CreateBasketDocument,
  Basket,
  CreateMinimalCustomerDocument,
  CustomerInput,
} from '@flashpack/graphql';
import { useRouting } from '@src/shared/hooks/useRouting';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import Layout from '@src/shared/Layout/Layout';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import {
  Stack,
  Box,
  composeValidators,
  Divider,
  FormDateInput,
  FormTextInput,
  GenericError,
  LoadingButton,
  Validator,
} from 'design-system';
import { GenderDropdown } from '@src/shared/GenderDropdown/GenderDropdown';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '@src/airwallex/AirwallexDropIn';
import { BookDepartureFormValues } from '@src/book-departure/BookDeparturePage';
import { PaymentOptions } from '@src/book-departure/payment-options/PaymentOptions';
import PromotionalCode from '@src/book-departure/promotional-code/PromotionalCode';
import { TotalToPay } from '@src/pay/TotalToPay';
import { Extras } from '@src/shared/extras/Extras';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { BookingTermsAndConditionsFormField } from '@src/shared/booking-terms-and-conditions/BookingTermsAndConditionsFormField';
import { useFirebaseAuth } from '@src/auth/useAuthentication';

const defaultBasket: Basket = {
  __typename: 'Basket',
  id: 1, // Change to string
  tripPrice: 100,
  tripTotal: 100,
  tourDeposit: 100,
  departureDate: new Date().toISOString(),
  availablePaymentOptions: [
    {
      __typename: 'PaymentOption',
      type: PaymentOptionType.FullBalance,
    },
    {
      __typename: 'PaymentOption',
      type: PaymentOptionType.BookNowPayLater,
    },
  ],
  selectedPaymentOption: {
    __typename: 'SelectedPaymentOption',
    optionType: PaymentOptionType.FullBalance,
  },
  currency: CustomerCurrency.Usd,
  joinWhatsAppGroup: false,
  appliedPromocodes: null,
  // Add missing required properties
  earlyBirdDiscount: null,
  insiderDiscount: null,
  reservation: null,
  terms: null,
} as Basket;

type MinimalCustomerInput = Omit<
  CustomerInput,
  | 'contactEmail'
  | 'contactPhone'
  | 'contactSms'
  | 'referrer'
  | 'nationality'
  | 'phoneCountryCode'
  | 'phoneNumber'
>;

export const CombinedCheckoutPage: React.FC = () => {
  const [createPaymentIntent] = useMutation(CreatePaymentIntentDocument);
  const [createCustomer] = useMutation(CreateMinimalCustomerDocument);
  const { queryParams, navigate } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { authenticated } = useFirebaseAuth();

  const { departureCode, currencyCode } = queryParams;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;
  const { signInWithEmailAndPassword } = useFirebaseAuth();
  const [error, setError] = useState<string | null>(null);

  const [createBasket, { data: basketData }] = useMutation(CreateBasketDocument, {
    variables: {
      input: {
        departureCode,
        currency: customerCurrency,
      },
      skip: !authenticated,
    },
  });
  const basket = basketData?.createBasket || defaultBasket;
  const [isAirwallexDropInOpen, setIsAirwallexDropInOpen] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [amountToPay, setAmountToPay] = useState<number | null>(basket.tripTotal); //TODO: update
  const [updateBasket] = useMutation(UpdateBasketDocument);
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const handleAirwallexSuccess = useCallback(() => {
    navigate(CheckoutRoutePath.PAYMENT_SUCCESS.value, { preserveExisting: true });
  }, [navigate]);

  const handleAirwallexError = useCallback((_error: unknown) => {
    setPaymentError(true);
  }, []);

  if (orderDetailsLoading) {
    return (
      <Layout
        HeroComponent={
          <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
        }
      >
        <PageFormLoader />
      </Layout>
    );
  }
  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    const { data: paymentIntent } = await createPaymentIntent({
      variables: {
        input: {
          amount: basket.tripTotal, // todo: remove it; take the value in the server
          currency: customerCurrency,
          basketId: basket.id,
        },
      },
    });
    if (paymentIntent) {
      setAmountToPay(paymentIntent.createPaymentIntent.amount);
      return paymentIntent.createPaymentIntent as CreatedPaymentIntent;
    }
    throw new Error('Failed to create intent!');
  };

  const continueToPayment = async (values: BookDepartureFormValues) => {
    const {
      selectedPaymentOptionType,
      selectedPaymentOptionDepositAmount,
      selectedPaymentOptionStartDate,
    } = values;

    const depositAmount =
      selectedPaymentOptionType === PaymentOptionType.FullBalance
        ? null
        : selectedPaymentOptionDepositAmount;

    await updateBasket({
      variables: {
        input: {
          id: basket.id,
          departureCode,
          acceptedTermsAndConditions: values.acceptedTermsAndConditions,
          joinWhatsAppGroup: values.joinWhatsAppGroup,
          selectedPaymentOption: {
            optionType: selectedPaymentOptionType as PaymentOptionType,
            depositAmount,
            paymentPlan: selectedPaymentOptionStartDate
              ? {
                  startDate: new Date(selectedPaymentOptionStartDate).toISOString(),
                }
              : null,
          },
        },
      },
    }),
      setIsAirwallexDropInOpen(true);
  };

  const signUp = async (values: MinimalCustomerInput) => {
    await createCustomer({
      variables: {
        input: {
          ...values,
        },
      },
    });
    await signInWithEmailAndPassword(
      { email: values.email, password: values.password },
      {
        onError: (e) => {
          setError(e.message);
        },
      },
    );
    await createBasket();
  };

  const departureDate = basket?.departureDate;
  const termsAndConditions = basket?.terms?.content;

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
      }
    >
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={2}>
            <PageFormTitle>Book your trip</PageFormTitle>
            <Form<MinimalCustomerInput>
              //   initialValues={initialFormValues}
              onSubmit={(values) => {
                // setError(null);
                return signUp(values);
              }}
              render={({ handleSubmit, submitting }) => {
                return (
                  <form
                    autoComplete="on"
                    id="user-registration-form"
                    onSubmit={(e) => {
                      void handleSubmit();
                      e?.preventDefault();
                    }}
                  >
                    <Stack gap={2} marginBottom={3.5}>
                      <FormTextInput
                        name="email"
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-email',
                          placeholder: 'Enter email address...',
                          label: 'Email Address',
                          testid: 'create-account-email',
                          size: 'large',
                          autoComplete: 'email',
                        }}
                        // validate={validateEmail} // TODO: reenable
                      />
                      <FormTextInput
                        name="password"
                        validateFields={[]}
                        textInputProps={{
                          placeholder: 'Enter new password...',
                          label: 'Create Password',
                          testid: 'create-account-password',
                          type: 'password',
                          size: 'large',
                          autoComplete: 'new-password',
                        }}
                        validate={composeValidators(
                          Validator.required,
                          Validator.validPassword,
                        )}
                      />
                      <FormTextInput
                        name="firstName"
                        validate={Validator.required}
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-first-name',
                          autoComplete: 'given-name',
                          placeholder: 'Enter first name...',
                          label: 'First Name',
                          testid: 'create-account-first-name',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="lastName"
                        validateFields={[]}
                        textInputProps={{
                          id: 'user-family-name',
                          autoComplete: 'family-name',
                          placeholder: 'Enter last name...',
                          label: 'Last Name',
                          testid: 'create-account-last-name',
                          size: 'large',
                        }}
                        validate={Validator.required}
                      />

                      <GenderDropdown validateFields={[]} />
                      <FormDateInput
                        name="dateOfBirth"
                        validateFields={[]}
                        label="Date of Birth"
                        testid="create-account-date-of-birth"
                        inputProps={{
                          id: 'user-date-of-birth',
                        }}
                        dayInputProps={{
                          id: 'user-date-of-birth-day',
                          name: 'bday-day',
                          autoComplete: 'bday-day',
                        }}
                        monthInputProps={{
                          id: 'user-date-of-birth-month',
                          name: 'bday-month',
                          autoComplete: 'nope', // 'bday-month',
                        }}
                        yearInputProps={{
                          id: 'user-date-of-birth-year',
                          name: 'bday-year',
                          autoComplete: 'bday-year',
                        }}
                        validate={composeValidators(
                          Validator.required,
                          Validator.dateIsInFlashpackerRange,
                        )}
                      />
                    </Stack>
                    <LoadingButton
                      type="submit"
                      data-testid="create-account-submit"
                      variant="contained"
                      sx={{ marginTop: 4, alignSelf: 'flex-start' }}
                      loading={submitting}
                    >
                      Continue
                    </LoadingButton>
                  </form>
                );
              }}
            />

            {authenticated && (
              <Extras onChange={() => setIsAirwallexDropInOpen(false)} basket={basket} />
            )}

            <Form<BookDepartureFormValues>
              onSubmit={(values) => {
                setAmountToPay(null);
                return continueToPayment(values);
              }}
              keepDirtyOnReinitialize={true}
              //   initialValues={initialValues}
              render={({ handleSubmit, submitting, values, form }) => {
                return (
                  <form
                    autoComplete="on"
                    id="checkout-book"
                    onSubmit={(e) => {
                      setIsAirwallexDropInOpen(false);

                      e.preventDefault();
                      void handleSubmit(e);
                    }}
                  >
                    <Stack gap={3}>
                      <Divider sx={{ m: 0 }} />
                      {orderDetailsData?.orderDetails.promoCodesEnabled && (
                        <PromotionalCode
                          basketId={basket.id}
                          currencyCode={currencyCode}
                          departureCode={departureCode}
                          appliedPromocodes={basket.appliedPromocodes}
                          onChange={() => setIsAirwallexDropInOpen(false)}
                        />
                      )}
                      <PaymentOptions
                        basket={basket}
                        departureDate={departureDate ?? undefined}
                        paymentPlanStartDate={values.selectedPaymentOptionStartDate}
                        form={form}
                      />
                      {termsAndConditions && (
                        <Box mt={1}>
                          <Field
                            name="acceptedTermsAndConditions"
                            type="checkbox"
                            validate={Validator.required}
                          >
                            {(props) => (
                              <BookingTermsAndConditionsFormField
                                termsAndConditionsHTML={termsAndConditions}
                                {...props}
                              />
                            )}
                          </Field>
                        </Box>
                      )}
                      <FormSpy
                        subscription={{
                          modifiedSinceLastSubmit: true,
                          invalid: true,
                        }}
                      >
                        {(props: {
                          modifiedSinceLastSubmit: boolean;
                          invalid: boolean;
                        }) => {
                          return isAirwallexDropInOpen &&
                            !props.modifiedSinceLastSubmit &&
                            !props.invalid ? (
                            <>
                              <TotalToPay
                                currency={customerCurrency}
                                finalCost={amountToPay}
                                selectedPaymentOption={basket.selectedPaymentOption}
                              />
                              <AirwallexDropInElement
                                onSuccess={handleAirwallexSuccess}
                                onError={handleAirwallexError}
                                createIntent={createIntent}
                                dropInType="payment"
                              />
                            </>
                          ) : (
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              data-testid="continue-to-payment"
                              sx={{ marginTop: '16px', alignSelf: 'self-start' }}
                              loading={submitting}
                            >
                              Pay
                            </LoadingButton>
                          );
                        }}
                      </FormSpy>
                      <>add error message</>
                      {!!error && <GenericError error={error} />}
                      {!!paymentError && (
                        <GenericError
                          error={
                            'Something went wrong when processing your payment. Please try again.'
                          }
                        />
                      )}
                    </Stack>
                  </form>
                );
              }}
            />
          </Stack>
        }
        additionalSection={
          <>
            <OrderDetails
              loading={orderDetailsLoading}
              orderDetails={orderDetailsData?.orderDetails}
            />
            <TotalToPay
              currency={customerCurrency}
              finalCost={amountToPay}
              selectedPaymentOption={basket.selectedPaymentOption}
            />
          </>
        }
      />
    </Layout>
  );
};
