import React, { useMemo } from 'react';
import {
  Box,
  composeValidators,
  Divider,
  FormPhoneInput,
  LoadingButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Validator,
} from 'design-system';
import { Field, Form } from 'react-final-form';
import JoinWhatsAppIcon from './images/phone-conversation.svg';
import { TripBookedSection } from './TripBookedSection';

interface FormValues {
  whatsAppPhoneNumber: string;
}

interface JoinTripsWhatsappFormPropTypes {
  onFormSubmit: (values: FormValues) => Promise<void>;
  defaultPhoneNumber: string;
}

const JoinTripsWhatsappForm: React.FC<JoinTripsWhatsappFormPropTypes> = ({
  onFormSubmit,
  defaultPhoneNumber,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialValues = useMemo(
    () => ({ whatsAppPhoneNumber: defaultPhoneNumber }),
    [defaultPhoneNumber],
  );

  return (
    <Form<FormValues>
      onSubmit={(values) => onFormSubmit(values)}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void handleSubmit();
          }}
        >
          <Stack gap={1}>
            <Box>
              <Field
                name="whatsAppPhoneNumber"
                validateFields={[]}
                validate={composeValidators(
                  Validator.required,
                  Validator.validPhoneNumber,
                )}
              >
                {(props) => (
                  <FormPhoneInput
                    {...props}
                    defaultCallingCode="+1"
                    data-testid="create-account-phone-number"
                    label="Mobile number*"
                    callingCodeInputProps={{
                      size: isMobile ? 'small' : 'medium',
                    }}
                    numberInputProps={{
                      name: 'whatsAppPhoneNumber',
                      placeholder: 'Enter phone number...',
                      size: isMobile ? 'fullWidth' : undefined,
                    }}
                  />
                )}
              </Field>
            </Box>
            <Typography variant="Body S" color="text.secondary">
              Your number will be visible to all other members of your trip
            </Typography>
            <LoadingButton
              loading={submitting}
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: 'fit-content' }}
              onClick={() => void handleSubmit()}
            >
              Join Group
            </LoadingButton>
          </Stack>
        </form>
      )}
    />
  );
};

interface JoinTripsWhatsappPropTypes {
  onPhoneNumberSubmit: (phoneNumber: string) => Promise<void>;
  userDefaultPhoneNumber?: string;
  activeSection: 'join-whatsapp-phone-number-prompt' | 'join-whatsapp-success';
}

export const JoinTripsWhatsapp: React.FC<JoinTripsWhatsappPropTypes> = ({
  onPhoneNumberSubmit,
  userDefaultPhoneNumber,
  activeSection,
}) => {
  if (activeSection === 'join-whatsapp-success') {
    return (
      <>
        <TripBookedSection
          icon={<img src={JoinWhatsAppIcon} alt="Join WhatsApp" />}
          title="Join your trip's WhatsApp"
          description="Great, that’s all done! We will add you to the trip group 14 days before you depart"
        />
        <Divider sx={{ mt: 2 }} />
      </>
    );
  }

  return (
    <>
      <TripBookedSection
        icon={<img src={JoinWhatsAppIcon} alt="Join WhatsApp" />}
        title="Join your trip's WhatsApp"
        description="14 days before you depart, we'll introduce you to your group and your Pack Leader. The group will be used for daily communication on the trip."
        content={
          <JoinTripsWhatsappForm
            onFormSubmit={(values) => onPhoneNumberSubmit(values.whatsAppPhoneNumber)}
            defaultPhoneNumber={userDefaultPhoneNumber ?? ''}
          />
        }
      />
      <Divider sx={{ mt: 2 }} />
    </>
  );
};
