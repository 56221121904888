import { useQuery } from '@apollo/client';
import { Basket, CurrentMinimalCustomerDetailsDocument } from '@flashpack/graphql';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import ReservationTimer from '@src/book-departure/reservation-timer/ReservationTimer';
import { AgeSweetspotBanner } from '@src/shared/age-sweetspot-banner/AgeSweetspotBanner';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { CreateAccountSection } from '@src/v3/checkout-sections/hold-your-spot/CreateAccountSection';
import { Box, Skeleton, useMediaQuery, useTheme } from 'design-system';
import { FC } from 'react';

interface PropTypes {
  basket?: Basket | null;
  loading?: boolean;
  isInAgeSweetspot?: boolean;
  isOver45Trip?: boolean;
  showReservationTimer?: boolean;
}

export const HoldYourSpotSection: FC<PropTypes> = ({
  basket,
  loading,
  isInAgeSweetspot = false,
  isOver45Trip = false,
  showReservationTimer = true,
}) => {
  const { currentUser } = useCustomerAuthorization();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: customerDetailsData, loading: customerDetailsLoading } = useQuery(
    CurrentMinimalCustomerDetailsDocument,
    {
      skip: !currentUser,
    },
  );

  const title = customerDetailsData?.currentCustomerDetails
    ? `Holding your spot, ${customerDetailsData.currentCustomerDetails.firstName}!`
    : 'Hold your spot';

  if (!basket && !loading && !customerDetailsLoading && !currentUser) {
    return (
      <CheckoutExpandableSection title={title} open topDivider={false}>
        <CreateAccountSection />
      </CheckoutExpandableSection>
    );
  }

  if (!showReservationTimer) {
    return null;
  }

  const content = (
    <>
      <Box
        mt={isMobile ? 0 : 3}
        sx={{
          ...(isMobile && {
            px: 3,
            py: 2,
            backgroundColor: theme.palette.principal.grey30,
            marginLeft: '-16px',
            marginRight: '-16px',
            position: 'fixed',
            minWidth: '100vw',
            minHeight: '72px',
            top: 74,
            zIndex: 1,
          }),
        }}
      >
        {(loading || customerDetailsLoading) && (
          <>
            <Skeleton
              variant="rectangular"
              height={18}
              width="100%"
              animation={'pulse'}
              sx={{ borderRadius: '4px', mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              height={18}
              width="100%"
              animation={'pulse'}
              sx={{ borderRadius: '4px' }}
            />
          </>
        )}
        {!loading && !customerDetailsLoading && basket?.reservation && (
          <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
        )}
      </Box>
    </>
  );

  if (isMobile) {
    return (
      <>
        {isOver45Trip && isInAgeSweetspot && <AgeSweetspotBanner />}
        {content}
      </>
    );
  }

  return (
    <>
      {isOver45Trip && isInAgeSweetspot && <AgeSweetspotBanner />}
      <CheckoutExpandableSection title={title} open topDivider={false}>
        {content}
      </CheckoutExpandableSection>
    </>
  );
};
