import { useMutation } from '@apollo/client';
import { Basket, UpdateCustomerMarketingFieldsDocument } from '@flashpack/graphql';
import { howDidYouHearAboutUsOptions } from '@src/create-account/HowDidYouHearAboutUs';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { useRouting } from '@src/shared/hooks';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import {
  Box,
  CheckboxFormField,
  FormControlLabel,
  FormDropdown,
  FormTextInput,
  LoadingButton,
  Typography,
  useMediaQuery,
  useTheme,
  Validator,
} from 'design-system';
import { FC } from 'react';
import { Field, Form } from 'react-final-form';

type PropTypes = {
  activeSection: CheckoutSectionName;
  completed: boolean;
  basket: Basket;
};

type FormValues = {
  referrer: string;
  referrerOther: string;
  discoveryTimeframe: string;
  contactPhone: boolean;
  contactSms: boolean;
  contactEmail: boolean;
};

export const FinishSection: FC<PropTypes> = ({ activeSection, completed }: PropTypes) => {
  const { navigate, updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const [updateCustomerMarketingFields] = useMutation(
    UpdateCustomerMarketingFieldsDocument,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onSubmit = async (values: FormValues) => {
    await updateCustomerMarketingFields({
      variables: { input: values },
    });
    navigate(CheckoutRoutePath.YOUR_TRIP_IS_BOOKED.value, {
      preserveExisting: true,
      toOmit: ['section'],
    });
  };

  return (
    <CheckoutExpandableSection
      title="Finish"
      open={activeSection === CheckoutSectionName.Finish}
      completed={completed}
      onEdit={() => {
        updateQueryParams({
          toAdd: { section: CheckoutSectionName.Finish },
          preserveExisting: true,
        });
      }}
    >
      <Form<FormValues>
        onSubmit={onSubmit}
        render={({ handleSubmit, values, submitting }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit();
            }}
          >
            <Box mt={3}>
              <Typography variant={isMobile ? 'H6' : 'Body L bold'} mb={1}>
                How did you hear about us?
              </Typography>
              <FormDropdown
                name="referrer"
                options={howDidYouHearAboutUsOptions}
                validate={Validator.required}
                TextInputProps={{
                  size: 'large',
                  placeholder: 'Select...',
                  fullWidth: true,
                  testid: 'how-did-you-hear-about-us',
                }}
              />
              {values.referrer === 'Other' && (
                <Box mt={2}>
                  <FormTextInput
                    name="referrerOther"
                    textInputProps={{
                      placeholder: 'Tell us how you heard about us...',
                      label: 'Please specify',
                      testid: 'referrer-other',
                      size: 'large',
                      fullWidth: true,
                      multiline: true,
                      minRows: 2,
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box mt={3}>
              <Typography variant={isMobile ? 'H6' : 'Body L bold'} mb={1}>
                How long ago did you find out about Flash Pack?
              </Typography>
              <FormDropdown
                name="discoveryTimeframe"
                options={howLongAgoDidYouFindOutAboutFlashPackOptions}
                validate={Validator.required}
                TextInputProps={{
                  size: 'large',
                  placeholder: 'Select...',
                  fullWidth: true,
                  testid: 'how-long-ago-did-you-find-out-about-flash-pack',
                }}
              />
            </Box>
            <Box mt={3}>
              <StayInTouch values={values} />
            </Box>
            <LoadingButton
              type="submit"
              loading={submitting}
              sx={{
                mt: 3,
                alignSelf: 'flex-start',
                backgroundColor: theme.palette.principal.black,
              }}
              variant="contained"
            >
              Save & finish
            </LoadingButton>
          </form>
        )}
      />
    </CheckoutExpandableSection>
  );
};

enum HowLongAgoDidYouFindOutAboutFlashPack {
  LessThanMonth = 'less-than-one-month',
  Approximately3Months = '3-months',
  Over3Months = 'over-3',
}

const howLongAgoDidYouFindOutAboutFlashPackOptions = [
  {
    label: 'Less than a month ago',
    value: HowLongAgoDidYouFindOutAboutFlashPack.LessThanMonth,
  },
  {
    label: 'Approximately 3 months ago',
    value: HowLongAgoDidYouFindOutAboutFlashPack.Approximately3Months,
  },
  {
    label: 'Over 3 months ago',
    value: HowLongAgoDidYouFindOutAboutFlashPack.Over3Months,
  },
];

const StayInTouch = ({
  values,
}: {
  values: { contactPhone: boolean; contactSms: boolean; contactEmail: boolean };
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography variant={isMobile ? 'H6' : 'Body L bold'} mb={2}>
        Stay in touch
      </Typography>
      <Typography variant="Body S" paddingBottom={2}>
        Flash Pack would love to get in touch from time to time with exclusive offers and
        solo travel inspiration. If you’re happy for us to do so, please tick your
        preferred form of contact below.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControlLabel
          checked={values.contactPhone}
          label="Phone"
          data-testid="contact-phone"
          control={
            <Field<boolean>
              name="contactPhone"
              label="Email"
              type="checkbox"
              defaultValue={false}
              validateFields={[]}
              component={CheckboxFormField}
            />
          }
        />
        <FormControlLabel
          checked={values.contactSms}
          label="Text"
          data-testid="contact-text"
          control={
            <Field<boolean>
              name="contactSms"
              label="Email"
              type="checkbox"
              defaultValue={false}
              validateFields={[]}
              component={CheckboxFormField}
            />
          }
        />
        <FormControlLabel
          checked={values.contactEmail}
          label="Email"
          data-testid="contact-email"
          control={
            <Field<boolean>
              name="contactEmail"
              label="Email"
              type="checkbox"
              defaultValue={false}
              validateFields={[]}
              component={CheckboxFormField}
            />
          }
        />
      </Box>
    </>
  );
};

export const FinishSectionTitle = () => {
  return <CheckoutExpandableSection title="Finish" />;
};
